@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.equipment-select-option {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;

  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.equipment-multibadge-container {
  display: flex;
  margin-left: 10px;
}
.equipment-badge {
  border-color: #ccc;
  min-height: 15px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 0 4px 0 4px;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.equipment-badge:not(:first-child) {
  margin-left: 4px;
}

.equipment-badge:hover {
  background-color: #ececeb;
  border-color: #262626;
  color: #262626;
}

.equipment-badge-icon {
  font-size: 9px;
}
