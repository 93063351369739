@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.running-tooltip {
  border-radius: 3px;
  box-shadow: 0 11px 17px 5px rgba(0, 0, 0, 0.5);
  padding: 10px 24px;
  position: absolute;
  right: 5px;
  text-align: left;
  font-size: $caption-text;
  bottom: -45px;
  transition: max-height 0.3s ease, padding 0.2s ease;
  width: 200px;
  z-index: 1000;

  &.light {
    background: $white;
    opacity: 1;
    color: #262626;
    .close {
      &:hover {
        cursor: pointer;
        background-color: #ededed;
      }
    }
  }

  .close {
    position: absolute;
    top: 2px;
    right: 2px;
    border-radius: 50%;
    font-size: $body-text;
  }

  .running-message {
    margin: 2px 0;
    line-height: 1.2em;
  }
}

.asset-panel-icons .validation {
  width: 24px;
  height: 24px;
  padding-top: 3px;
}

.tip-span > .validation-tab::before {
  content: '';
  width: 100%;
  border-top: solid 1px #ecf3fa;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

.icon-btn--active .icon-container .injected-svg {
  #network-error {
    @include applyTheme($themes) {
      fill: themed('contrast-light');
      color: themed('contrast-light');
    }
  }
}

.sliding-panel-tab > .custom-btn.primary-btn:disabled {
  cursor: not-allowed;
}
