@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.title-bar {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  .title {
    font-weight: 600;
    margin: 0;
    font-size: $large-header;
    @include applyTheme($themes) {
      color: themed('text-header');
    }
  }
  .secondary-title {
    margin: 5px 0 0 0;
    font-size: $caption-text;
    font-weight: 400;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
  }
}
