@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.text-input-all {
  margin: 3px 0;
  display: flex;
  width: 100%;
  .text-input {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    width: 100%;
    .input-with-label {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &__input {
      background-color: $ge-gray-blue;
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 2px 6px;
      font-size: $body-text;
      color: $white;
      height: 32px;
      width: 100%;
      &.text-invalid {
        border-color: $warning-red;
      }
      &::placeholder {
        color: $ge-gray-h3;
      }
    }
    &__label {
      display: flex;
      align-items: center;
      font-size: inherit;
      white-space: nowrap;
      margin-right: 40px;
    }

    &-container {
      display: flex;
      width: 100%;

      .unit {
        display: flex;
        align-items: center;
        padding-left: 5px;
      }
    }
  }

  .text-invalid {
    border: 1px solid $warning-red !important;
  }

  .input-error {
    font-size: $caption-text;
    color: $warning-red;
    text-align: right;
    width: 100%;
  }

  .text-input__input {
    border-color: transparent;
    background-color: $ge-gray-blue;
    color: $white;
    &:hover,
    &:focus {
      border: 1px solid $ge-border;
      outline: $ge-blue;
    }
    &:disabled {
      background-color: $light-gray;
      color: $dark-gray;
      cursor: not-allowed;
      border: none;
    }
  }

  &.primary {
    .text-input {
      margin: 0;
    }
    .text-input__input {
      background-color: $ge-gray-blue;
      width: 100%;
      border: 1px solid transparent;
      color: $white;
    }
    .input-with-label {
      display: block;
      .text-input__label {
        margin-bottom: 5px;
        color: $ge-gray-h3;
        font-size: 14px;
      }
    }
    .input-error {
      text-align: left;
      margin-top: 3px;
    }
  }
}
