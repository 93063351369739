@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.icon-btn-container {
  .icon-btn {
    @extend .flex-centered;
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 0;
    background-color: transparent;

    &.round {
      border-radius: 50%;
    }

    .icon-container {
      @extend .flex-centered;
      height: 24px;
      width: 24px;
      font-size: $icon-size;
      color: #fff;
      .material-icons {
        font-size: inherit;
        &:hover {
          color: #fff !important;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      .icon-btn-image {
        background-color: transparent;
      }
    }
  }

  .icon-container {
    color: #959595;
  }

  .custom-btn.icon-btn:hover {
    // background-color: #f5f5f5;
    background-color: $ge-blue;

    .icon-container {
      color: #fff;
    }
  }

  .custom-btn.icon-btn:disabled,
  .custom-btn.icon-btn:disabled:hover {
    background-color: transparent;
    .icon-container {
      color: #e2e2e2;
    }
  }

  .custom-btn.icon-btn--active .icon-container,
  .custom-btn.icon-btn--active:hover .icon-container {
    & > i {
      color: #00b4aa;
    }

    & svg {
      fill: #00b4aa;
    }
  }
  .has-notification {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 12px;
    right: 12px;
    border-radius: 50%;
    background-color: red;
    z-index: 100;
  }

  .has-count-notification {
    font-family: 'Roboto', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 3px;
    right: 5px;
    border-radius: 50%;
    background-color: #0337a4;
    color: #fff;
    font-size: 11px;
    z-index: 100;
    border: none;

    span {
      position: absolute;
      left: 6px;
      top: 3px;
      padding: 0;
      font-weight: bold;
    }
  }
}
