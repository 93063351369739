@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.select-dropdown-section {
  flex-direction: column;
  flex-grow: 1;

  .primary,
  .secondary {
    font-size: $small-header;
    .custom-select__menu,
    .custom-select__single-value,
    .custom-select__placeholder {
      font-size: $small-header;
      color: $white !important;
    }
  }

  .custom-icon {
    .icon-option-row svg {
      background-color: transparent !important;
    }
  }
  .default,
  .primary,
  .secondary {
    .custom-select__single-value {
      &--is-disabled {
        @include applyTheme($themes) {
          color: themed('label-light');
        }
      }
    }
    .custom-multi-select {
      &__control {
        min-height: 32px;
      }
    }
    .custom-multi-select__option--is-selected {
      background-color: $ge-gray-blue;
      color: #fff;
    }

    .custom-select__menu {
      border: 0.5px solid transparent !important;
      // border: 0px solid transparent !important;
      border-radius: 4px !important;
      background-color: #000 !important;
      box-shadow: none !important;
      margin-top: 4px;
    }

    .custom-select__menu {
      .custom-select__menu-list {
        .custom-select__option {
          &--is-selected {
            color: $white;
            border: transparent !important;
          }
        }
      }
    }
    .custom-select__menu {
      .custom-select__option {
        &--is-selected {
          font-weight: 600;
        }
        &--is-disabled {
          cursor: not-allowed;
        }
      }
    }
    .custom-select__control,
    .custom-select__control:hover {
      @include applyTheme($themes) {
        border: 1px solid $ge-gray-blue;
        // outline: themed('contrast-border');
      }
      min-height: 32px !important;
    }
    .custom-select__control {
      &--menu-is-open {
        border: 0px solid transparent !important;
        box-shadow: none;
      }
      .custom-select__value-container {
        &--has-value {
          .custom-select__single-value {
            color: #fff;
          }
        }
      }
    }

    .custom-select__control:focus-visible {
      border: 1px solid $ge-gray-blue !important;
    }

    .custom-select__control--is-disabled,
    .custom-multi-select__control--is-disabled {
      cursor: not-allowed;
      pointer-events: fill;
    }

    .custom-select__placeholder {
      @include applyTheme($themes) {
        color: themed('color-h3-ge') !important;
      }
    }
  }
  .text {
    font-size: $article-text;

    .custom-select__menu,
    .custom-select__single-value,
    .custom-select__placeholder {
      font-size: $article-text;
      color: $ge-gray-h3 !important;
    }

    .custom-select__control {
      min-height: 20px;
      border: none;
    }
  }

  .default {
    font-size: 14px !important;
  }
  .default,
  .secondary,
  .primary {
    .custom-select__control {
      min-height: 32px;
    }
    .custom-multi-select__control:hover,
    .custom-multi-select__control--is-focused,
    .custom-select__control:hover,
    .custom-select__control--is-focused {
      @include applyTheme($themes) {
        border: 1px solid transparent;
      }
    }
  }
  .select__label {
    font-size: $caption-text;
    margin-bottom: 3px;
  }

  &.label-left {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-open {
    border: 0px solid transparent !important;
    // box-shadow: 8px 5px 20px -5px rgba(160, 160, 160, 0.9) !important;
    border-radius: 4px;
    .custom-select__control,
    .custom-select__control--is-focused {
      border: 1px solid transparent !important;
    }
  }
}

.light.select-dropdown.primary,
.light.select-dropdown.secondary,
.light.select-dropdown.text {
  .option-row svg {
    background-color: $white;
  }
}
.dark.select-dropdown.primary,
.dark.select-dropdown.secondary,
.light.select-dropdown.text {
  .option-row svg {
    background-color: $white;
  }
}
