@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.custom-scrollbar {
  width: 100%;
  .ps__rail-y.ps--clicking,
  .ps__rail-y:focus,
  .ps__rail-y:hover {
    background-color: transparent;
  }

  &--always-show {
    .ps__rail-y {
      opacity: 0.6;
    }
  }
}
