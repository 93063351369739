@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.distance-graph {
  &.card {
    min-height: 250px;
    margin: 0;
    display: flex;
    .content {
      height: 100%;
      width: 100%;
      min-height: 250px;
      min-width: 200px;
    }

    .legend {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: $caption-text;
      margin-top: 15px;
    }

    .legend-entry {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      &.load-legend {
        color: #fc5830;
        border-color: #fc5830;
        .axis-circle {
          background-color: #fc5830;
        }
      }

      &.generation-legend {
        flex-direction: column;
        color: #06afa8;
        border-color: #06afa8;
        .axis-circle {
          background-color: #06afa8;
        }
        .legend-main {
          display: flex;
          align-items: center;
          width: 100px;
        }
        .legend-caption {
          font-size: $caption-text;
          margin-top: 3px;
        }
      }

      &.pu-distance-legend {
        color: #de6e00;
        border-color: #de6e00;
        .axis-circle {
          background-color: #de6e00;
        }
      }
    }

    .axis-box {
      border-bottom: 2px solid;
      height: 2px;
      width: 25px;
      margin-left: 5px;
      margin-right: 5px;
    }

    .axis-circle {
      border-radius: 10px;
      height: 10px;
      width: 10px;
      margin: 0 10px;
    }

    .phase-selection-message {
      min-height: 16px;
    }

    .phase-selector {
      max-width: 150px;
      margin: 5px 0;
    }

    .graph-placeholder {
      @extend .flex-centered;
      border-radius: 4px;
      height: 225px;
      margin: 10px 0;
      width: 100%;
      color: $ge-gray-h3;
      border: 1px solid $ge-border;
      @include applyTheme($themes) {
        background-color: themed('background-ge-dark');
      }
    }

    .brush-label {
      text-align: center;
      margin-bottom: 5px;
    }

    .recharts-text tspan {
      @include applyTheme($themes) {
        fill: themed('text-main');
      }
    }

    .recharts-brush {
      rect {
        fill: transparent;
      }

      rect.recharts-brush-slide {
        @include applyTheme($themes) {
          fill: themed('text-main');
        }
      }
    }

    .recharts-brush-traveller rect {
      @include applyTheme($themes) {
        fill: themed('text-main');
      }
    }

    .recharts-brush-texts tspan {
      @include applyTheme($themes) {
        fill: transparent;
      }
    }

    .recharts-cartesian-axis-line,
    .recharts-cartesian-axis-tick-line {
      @include applyTheme($themes) {
        stroke: themed('text-main');
      }
    }

    .recharts-cartesian-grid-vertical line,
    .recharts-cartesian-grid-horizontal line {
      opacity: 0.4;
      @include applyTheme($themes) {
        stroke: themed('text-main');
      }
    }

    .recharts-rectangle.recharts-tooltip-cursor {
      fill-opacity: 0.5;
    }

    .custom-tooltip {
      padding: 10px;
      border: 1px solid black;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: $body-text;

      @include applyTheme($themes) {
        background-color: tryTheme('background-very-dark', rgba, 0.75);
        border-color: themed('background-very-dark');
        color: themed('text-main');
      }
    }
  }
}
