@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';
@import '../styles/_theme.scss';

.results-card {
  min-height: 50px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: $article-text;
  padding: 10px 15px;
  @include applyTheme($themes) {
    background-color: themed('ge-dark');
    color: themed('color-h3-ge');
  }
  .custom-btn.icon-btn:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  .results-card-header {
    display: flex;
    align-items: center;
    position: relative;
    .title {
      font-size: $small-header;
      margin: 5px 0;
      font-weight: 600;
      color: $ge-gray-h3;
      @include applyTheme($themes) {
        color: themed('color-h3-ge') !important;
      }
      .primary-title {
        color: $ge-gray-h3;
      }
    }
    .button-menu {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      justify-content: space-between;
      margin-right: -10px;
      .icon-btn-container .icon-btn,
      .icon-container {
        outline: none !important;
      }
    }
  }
  .violation-icon {
    font-size: $small-icon-size;
    padding-right: 5px;
    color: #de3e13;
    display: flex;
    align-items: center;
    &.low {
      color: #ffa500;
    }
  }
  .item-number {
    display: flex;
    padding: 3px 0;
    .item-value {
      display: flex;
      align-items: center;
      font-size: $article-text;
      width: 100%;
    }
  }
  .primary-results {
    .item-value {
      font-weight: 600;
      font-size: 16px;
    }
    .item-text-title {
      @include applyTheme($themes) {
        color: themed('text-body');
      }
    }
  }
  .item-text-title {
    margin: 5px 0 0 0;
    @include applyTheme($themes) {
      color: themed('label-light');
    }
  }

  button {
    border: none;
    background: transparent;
    cursor: pointer;
    @include applyTheme($themes) {
      color: themed('text-main');
    }
  }
  .add-new-btn {
    min-height: 180px;
    width: 100%;
  }
  .custom-select__dropdown-indicator,
  .calendar-button .icon-indicator {
    width: 20px;
  }
  .custom-select__dropdown-indicator svg,
  .calendar-button .material-icons {
    display: none;
  }
  .results-expandable-section {
    margin-top: 10px;
    @include applyTheme($themes) {
      border-top: 1px solid themed('contrast-border');
    }
  }
  .recharts-wrapper {
    text {
      @include applyTheme($themes) {
        fill: themed('text-main');
      }
    }
  }
}
.margin-10 {
  margin: 10px 0;
}
.include-border {
  @include applyTheme($themes) {
    border: 1px solid themed('border-ge');
  }
}
.title-bold .title,
.text-bold {
  font-weight: 600;
  color: $ge-gray-h3;
}
.light.results-comparison-card.summary-card .item-value {
  color: $white;
}
