@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.files-panel {
  width: 100%;
  height: 100%;

  .file-form-btn {
    margin-left: 10px;
    margin-top: 5px;
    width: 110px;
    height: 36px;
  }

  .file-form-input__label {
    height: 36px;
    width: 110px;
  }

  .create-button {
    width: 100px;
    pointer-events: none;

    @extend .flex-centered;
    border: none;
    border-radius: 4px;
    color: $white;
    cursor: pointer;
    font-size: $body-text;
    height: 36px;
    overflow: hidden;
    padding: 0 12px;

    .btn-loading {
      margin-left: 10px;
    }

    &.dark {
      background-color: $medium-teal;
      &:hover {
        background-color: $light-teal;
      }
    }

    &.light {
      background-color: $ge-blue;
      &:hover {
        background-color: $ge-button-hover;
      }
    }
  }

  .load-message,
  .error-message {
    padding-left: 10px;
  }

  .file-card {
    width: calc(100% - 10px);
    height: 100px;

    .button-container {
      display: flex;
      flex-direction: row;
    }

    .content {
      height: 40px;
      display: flex;
      flex-direction: column;
    }
  }
}
