@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.reliability-container {
  .text-input {
    position: relative;
    .number-input-unit {
      position: absolute;
      right: -15px;
      top: 10px;
    }
  }
  .text-input-group .input-error {
    text-align: left;
    left: 57%;
  }
  .label-caption {
    margin-top: -13px !important;
  }
  .input-with-label {
    display: flex;
    width: 170px;
    align-items: center;
  }
  .coordinates-table {
    .coord-heading {
      margin-left: 0 !important;
      min-width: 100px;
      justify-content: flex-end !important;
      font-size: $body-text;
    }
    .number-input-group {
      margin-left: 0 !important;
      .number-input__input {
        flex-grow: 1;
      }
    }
    .x-input {
      width: auto !important;
      margin-left: 10px;
    }
    .y-input {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .input-error {
      display: none;
    }
    .static-value {
      width: 70px;
      display: flex;
      align-items: center;
    }
  }
}
.restoration-section {
  display: flex;
  align-items: center;

  .number-input__label {
    margin-right: 20px;
  }
  #restoration-time {
    min-width: 60px;
    width: 100px;
  }
  .number-input__label {
    min-width: 275px;
  }
  .restoration-units {
    width: 150px;
  }
  .number-input-container {
    max-width: 100% !important;
    min-width: 100px !important;
    width: 100px !important;
  }
}
