@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.equipment-select-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: $body-text;
  width: 100%;
  text-align: left;
  padding-left: 41px;
  border-left: 8px solid transparent;
  cursor: pointer;
  background-color: #fff;
  color: #262626;
  &:hover {
    background-color: #f5f5f5;
  }

  &__selected {
    background-color: $very-light-gray;
    border-left: 8px solid $medium-gray;
    background-color: #f5f5f5;
    color: #262626;
    border-left-color: $medium-teal;
  }

  &:hover {
    background-color: #fafafa;
  }

  &[data-selected='true'] {
    border-left: 8px solid $dark-gray;
    border-color: $light-teal;
    color: $light-teal;
    padding-left: 42px;
  }

  .in-use-button {
    margin-right: 10px;
    min-width: 30px;
  }

  .add-info-icon {
    @extend .flex-centered;
    flex-shrink: 0;
    height: 19px;
    width: 19px;
    i {
      color: inherit;
    }
  }

  &:hover {
    background-color: #fafafa;
  }

  &[data-selected='true'] {
    background-color: #fff;
    border-color: #0079c1;
    color: #0079c1;
  }

  .equipment-type-tile {
    align-items: center;
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-size: $body-text;
    height: 40px;
    outline: none;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    span {
      align-items: center;
      display: flex;
    }

    .equipment-type-label {
      background-color: transparent;
      display: inline-block;
      height: 15px;
      margin-left: 15px;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 85%;
    }
  }
}
