@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.simple-range-slider {
  position: relative;
  width: 100%;
  height: 55px;

  &-rail {
    background-color: rgb(120, 120, 120);
    border-radius: 0;
    cursor: pointer !important;
    height: 30px;
    margin-top: 0;
    position: absolute;
    width: 100%;
  }

  &-handle {
    position: absolute;
    margin-left: -7.5px;
    margin-top: 7.5px;
    z-index: 4;
    width: 15px;
    height: 15px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: solid 1px #9ea2a3;
    background-color: $white;
  }

  &-track {
    position: absolute;
    height: 30px;
    z-index: 3;
    margin-top: 0;
    cursor: pointer !important;
    border: none;
    border-left: 1px solid $white;
    border-right: 1px solid $white;

    .zoom-button {
      @extend .flex-centered;
      background-color: transparent;
      border: none;
      color: lightgray;
      height: 100%;
      opacity: 0;
      transition: 0.2s ease opacity;
      width: 100%;
    }

    &:hover .zoom-button {
      opacity: 1;
    }
  }

  &-tick-line {
    position: absolute;
    margin-top: 0;
    margin-left: -0.5px;
    width: 1px;
    height: 30px;
    background-color: $ge-gray-h3;
    pointer-events: none;
    z-index: 2;
  }

  &-tick-value {
    background-color: transparent;
    border: none;
    top: 0px;
    color: $white;
    display: flex;
    align-items: flex-end;
    font-size: $caption-text;
    height: 30px;
    padding-left: 5px;
    padding-top: 2px;
    pointer-events: auto;
    position: absolute;
    text-align: left;
    user-select: none;
    z-index: 1;
  }

  .tick-container--missing-data {
    .range-slider-tick-value {
      background-color: $panel-black;
      color: $dark-gray;
    }
  }
}
