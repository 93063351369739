@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.impedance-container {
  margin: 5px 0;

  .impedance-label {
    font-size: $caption-text;
    color: $medium-gray;
  }

  .impedance-grid {
    width: 355px;
    position: relative;

    .overlay {
      @extend .flex-centered;
      background-color: rgba($dark-gray, 0.8);
      color: $white;
      font-family: 'Roboto', sans-serif;
      height: 136px;
      left: 96px;
      position: absolute;
      text-align: center;
      top: 50px;
      width: 258px;
    }
  }
  .impedance-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .impedance-cell {
    color: $medium-gray;
    width: 63px;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-size: $caption-text;
    margin: 5px 0;
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }
  }
  .impedance-value-part {
    color: $medium-gray;
    width: 63px;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: $caption-text;
    margin: 5px 0;
    .number-input__input {
      width: 100% !important;
      height: 40% !important;
      font-size: 11px;
    }
    .number-input-group {
      margin: 0px;
    }
    .number-input {
      margin: 0px;
    }
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }
  }

  .impedance-header {
    background-color: $medium-charcoal;
    color: white;
    font-size: $body-text;
    @include applyTheme($themes) {
      background-color: themed('background-neutral');
      color: themed('text-contrast');
    }
  }

  .impedance-value,
  .impedance-empty {
    font-size: $caption-text;
    input {
      padding-bottom: 4px;
      padding-left: 1px;
    }
  }
}
