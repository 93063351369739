/** AssetPanelValues Component Styles **/
.asset-panel-values {
  padding: 15px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &--expanded {
    padding: 15px 60px;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='text'] {
    text-transform: capitalize;
  }

  h4 {
    margin: 10px 0;
  }
  .list-value-row-label {
    width: 120px;
  }
  .section-header {
    line-height: 40px;
    margin: 0;
    @include applyTheme($themes) {
      color: themed('color-h3-ge');
    }
  }

  .editable-section {
    width: 350px;
    h3 {
      color: $ge-gray-h3;
    }
    .input-spacing {
      padding: 2.5px 0;
    }
  }

  .header-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-size: $caption-text;
    font-weight: normal;
    color: $medium-gray;
    margin-bottom: 10px;
    width: 355px;
    @include applyTheme($themes) {
      color: themed('text-main');
    }

    .display-only {
      .text-input__label {
        font-size: $caption-text;
        width: 120px;
        color: $ge-gray-h3;
      }
      .text-input__input:disabled {
        background-color: transparent;
      }
    }

    .conductor-section {
      display: flex;
      flex-direction: column;
    }

    // Style overrides for phase input in header
    .text-input__label {
      width: 80px;
    }

    .single-row-input .text-input-container {
      flex-grow: 1;
      margin: 0;
      max-width: inherit;
      width: auto;

      .text-input__input {
        text-align: left;
      }
    }

    .panel-header-row {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      height: 45px;

      .equipment-info-selector {
        width: 235px;
      }

      .left-label {
        width: 120px !important;
      }

      .right-align {
        display: flex;
        justify-content: flex-start;
        padding-left: 30px;
      }

      .dropdown-no-title {
        align-items: center;
        display: flex;
        flex-grow: 1;
        .info-tooltip {
          margin-left: 10px;
        }
      }

      .dropdown {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .phase-check-container {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        .input-group {
          display: flex;
          .checkbox {
            padding-right: 10px;
          }

          .custom-checkbox {
            margin: 0;
          }
        }
      }
    }

    .mode-dropdown {
      width: 355px;
      .dropdown-no-title {
        width: 100%;
        margin-left: 120px;
        margin-right: 0;
      }
      .help-placeholder {
        flex-shrink: 0;
        text-align: right;
        width: 38px;
      }
    }
    .phase-coordinates {
      display: flex;
      flex-direction: column;
      width: 100%;
      .display-only {
        .text-input__label {
          font-size: $caption-text;
          width: 110px;
        }
        .text-input__input:disabled {
          background-color: transparent;
        }
      }
    }
  }

  /** Per Phase Value Styles **/
  .per-phase-row-container {
    margin: 10px 0;
    width: 355px;

    .phase-row-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 5px 0 10px 0;
      border-bottom: 1px solid $ge-border;
      &-col {
        font-size: $caption-text;
        color: white;
        background-color: transparent;
        padding: 3px 5px;
        width: 50px;
        text-align: center;
        @include applyTheme($themes) {
          background-color: themed('background-transparent');
          color: themed('text-ge');
        }
      }
    }

    .per-phase-row {
      align-items: center;
      color: $medium-gray;
      display: flex;
      font-size: $caption-text;
      justify-content: space-between;
      border-bottom: 1px solid $ge-border;
      margin: 4px 0 10px 0;
      @include applyTheme($themes) {
        color: themed('text-main');
      }

      &-label {
        width: 70px;
        font-size: $caption-text;
        padding-bottom: 2px;
        color: $ge-gray-h3;
      }

      &-input {
        width: 50px;

        .input-error {
          margin: 0;
        }
      }

      &-total {
        color: $medium-gray;
        font-size: $caption-text;
        margin: 0;
        text-align: center;
        width: 50px;
        &--edited .number-input .number-input__input {
          border-bottom: 1px solid $light-teal;
        }
      }

      &-select {
        background-color: $dark-charcoal;
        border: none;
        border-bottom: 1px dashed $dark-gray;
        color: $white;
        width: 50px;
        &--edited {
          border-bottom: 1px solid $light-teal;
        }
        .Select-value-label {
          height: 25px;
          margin-left: 15px;
        }
        .Select-control {
          border: none !important;
          box-shadow: none;
        }

        &:hover {
          padding: 0;
        }
        &__option {
          background-color: $panel-black;
          &:focus {
            outline: none;
          }
        }
      }

      &-unit {
        align-items: center;
        background-color: transparent;
        color: inherit;
        display: flex;
        padding: 0 0 1px 4px;
        text-align: left;
        width: 35px;
      }

      &-placeholder {
        background-color: $dark-charcoal;
        border: none;
        width: 50px;
      }

      .no-total {
        &-label {
          width: 115px;
        }
        &-value {
          width: 0px;
        }
      }
    }

    .validation-container {
      text-align: right;
      margin-left: 132px;
      padding: 0;
      li {
        list-style-type: none;
        .phase-validation-message {
          font-size: $caption-text;
          color: $warning-red;
          width: 100%;
          text-align: left;
        }
      }
    }
    .validation-message {
      font-size: $caption-text;
      color: $warning-red;
      max-width: 87%;
      text-align: right;
      white-space: pre-line;
    }
  }
  .probability-label {
    padding-right: 10px;
    color: $ge-gray-h3;
    font-size: 12px;
  }
  .propability-section {
    margin: 15px 0;
    display: flex;
    .propability-value {
      font-size: 12px;
      padding: 0px 5px;
      width: 70px;
      text-align: center;
      background-color: transparent;
      @include applyTheme($themes) {
        color: themed('text-ge');
      }
    }
  }

  .single-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    font-size: $caption-text;
    color: $medium-gray;
    font-family: 'Roboto', sans-serif;
    margin: 5px 0 10px 0;
    width: 355px;
    @include applyTheme($themes) {
      color: themed('text-main');
    }

    &-input .text-input-container,
    &-input .number-input-container {
      align-items: flex-end;
      margin-left: 6px;
      max-width: 170px;
      .text-input__input {
        align-self: flex-start;
      }
    }

    &-value {
      width: 125px;
      background-color: transparent;
      border: none;
      border-bottom: 1px dashed $dark-gray;
      text-align: center;
      color: $medium-gray;
      display: inline-block;
      @include applyTheme($themes) {
        color: themed('text-main');
      }
      &--edited {
        border-bottom: 1px solid $light-teal;
      }
      &--editable {
        color: $white;
      }

      &.single-row-select {
        height: 25px;
        border: none;

        &.single-row-value--edited .custom-select__control {
          border-color: $light-teal;
        }
      }
    }

    &-input .validation-message {
      display: block;
    }

    &-unit {
      width: 42px;
      color: inherit;
      display: inline-block;
      padding-left: 10px;
    }
    .single-row-label {
      color: $ge-gray-h3;
    }
  }

  .recalc-container {
    display: flex;
    justify-content: flex-end;
    .recalc-button {
      display: flex;
      justify-content: center;
      font-size: $caption-text;
      padding-right: 17px;
      width: 145px;
    }
    min-height: 36px;
  }

  .expandable-section {
    flex-shrink: 0;
  }

  .unit-section {
    margin: 5px 0;
    width: 355px;
  }

  .unit-section-header,
  .unit-header {
    align-items: center;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    color: $ge-gray-h3;
  }

  .unit-section-header {
    padding: 5px 0;
    @include applyTheme($themes) {
      color: themed('text-ge');
    }

    .title-text {
      margin: 5px 0;
      color: $ge-gray-h3;
      font-size: 12px;
    }
  }

  .unit-header,
  .unit-header:hover {
    @include applyTheme($themes) {
      color: themed('color-h3-ge');
    }
  }

  .panel.single-row-input--editable.number-input-group .number-input__label {
    padding-bottom: 2px;
  }

  .panel.single-row-input {
    .text-input {
      &__label {
        font-size: 12px;
        color: $ge-gray-h3;
      }
      &__input {
        background-color: transparent;
        font-size: 12px;
        width: 120px;
        text-align: center;
      }
    }
  }

  .panel.per-phase-row-input {
    .text-input {
      &__label {
        font-size: 12px;
        color: $ge-gray-h3;
      }
      &__input {
        background-color: transparent;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .unit-fields-section {
    margin-top: 5px;
  }
}
