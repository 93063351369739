@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.panel-header-new {
  background-color: $ge-dark;
  border-radius: 4px;
  margin: 8px 8px 0 0;
  height: auto;
  .panel-header-contents {
    height: 93px;
  }

  .panel-header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    border-bottom: 1px solid $ge-border;
    h3 {
      font-size: 13px;
      font-weight: 600;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      height: 48px;
      color: $ge-gray-h3;
    }
  }

  .panel-header-summary {
    font-size: 12px;
    line-height: 16px;
    padding: 0px 15px;
    height: 45px;
    display: flex;
    align-items: center;
    h3 {
      font-weight: bold;
      font-size: 12px;
    }
  }
}

.feeder-selected-content {
  margin: 8px 8px 0 0;
  background-color: $ge-dark;
  border-radius: 4px;
}

.substation-selected-content {
  border-radius: 4px;
  margin: 8px 8px 0 0;
  background-color: $ge-dark;
}

.container-selection-content {
  height: 100px;
  margin-top: 8px;
  .radio-check-select {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .radio-wrap {
      padding: 0 5px;
      .radio-btn-group .radio-btn--selected .radio-btn-fill {
        background-color: $ge-blue !important;
      }
      .radio-btn-group .radio-btn-input {
        &:focus + .option-label-container label .radio-btn {
          box-shadow: none;
        }
      }
    }
  }
  .panel-divider {
    margin: 0 15px;
    border-top: $ge-border;
  }
  .panel-header-container {
    display: flex;
    height: 56px;
    align-items: center;
    padding: 0 15px;
    .panel-icon-container {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .container-select-section {
      width: 100%;
      margin-left: 0px;
      margin-top: -8px;

      .feeder-select {
        .custom-multi-select__placeholder {
          font-size: 12px;
          position: relative;
          top: 8px;
          color: $ge-gray-h3;
        }
        .custom-multi-select__value-container {
          padding: 0;
          color: $white;
        }
        .custom-select__value-container {
          padding: 0;
          position: relative;
          top: 1px;
        }
        .custom-multi-select__control,
        .custom-select__control {
          border-left: 0 solid transparent;
          border-right: 0 solid transparent;
          border-top: 0 solid transparent;
          border-bottom: 0.5px solid #91a6ba;
          border-radius: 0;
        }

        .custom-multi-select__control:hover,
        .custom-select__control:hover {
          border-left: 0 solid transparent;
          border-right: 0 solid transparent;
          border-top: 0 solid transparent;
          border-bottom: 0.5px solid #91a6ba;
          border-radius: 0;
        }
        .custom-select__single-value {
          color: $white;
          font-size: 12px;
          top: 60%;
          padding: 0px;
        }
      }
    }
  }
}

.delete-container-edit-mode-feeder {
  position: relative;
  top: 82px;
  padding: 0 15px;
  width: 70%;
  left: 30px;
  z-index: 1;
  position: relative;
  top: 60px;
  input {
    border-bottom: 0.5px solid $info;
  }
}

.delete-container-edit-mode-substation {
  position: relative;
  top: 20px;
  padding: 0 15px;
  width: 70%;
  left: 30px;
  z-index: 1;

  input {
    border-bottom: 0.5px solid $info;
  }
}

.container-tooltip {
  @extend .flex-centered;
  flex-direction: column;
  padding: 5px;
  max-height: 190px;
  &-header {
    color: $medium-teal !important;
    font-size: $body-text;
    margin-bottom: 5px;
  }
  &-contents {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .multi-container-scroll {
      max-height: 140px;
      .ps__rail-x {
        display: none;
      }
      .ps__rail-y {
        width: 8px;
        z-index: 100;
        margin-right: 3px;
        right: 0px;
        &:hover .ps__thumb-y {
          background-color: rgba($ge-blue, 0.75);
        }
      }
      .ps__rail-y.ps--clicking .ps__thumb-y,
      .ps__rail-y:focus > .ps__thumb-y,
      .ps__rail-y:hover > .ps__thumb-y {
        width: 6px;
      }

      .ps__thumb-y {
        background-color: rgba($ge-blue, 0.5);
      }
    }
  }
}

.container-name-badge {
  align-items: center;
  background-color: $ge-blue;
  border-radius: 4px;
  color: $white;
  cursor: default;
  display: flex;
  font-size: $body-text;
  justify-content: space-between;
  margin-top: 3px;
  padding: 2px 5px;
  display: table;
}
