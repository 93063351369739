@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import 'src/components/styles/base.scss';
@import 'src/components/styles/_theme.scss';

.scenario-generation {
  @include applyTheme($themes) {
    height: 100%;
    overflow-y: auto;
    background-color: themed('background-ge-dark');
    color: themed('text-main');
    .margin-custom {
      margin: 25px 0 10px 0;
    }
    .results-card {
      font-size: $small-header;
      color: $white;
    }
    .grid-layout {
      padding: 0;
    }
    .grid-layout,
    .scenario-view,
    .scenario-view > .grid-columns,
    .schedule-panel {
      height: 100%;
      overflow-y: auto;
    }
    .scenario-view {
      .single-column {
        padding: 20px 20px 0 20px;
        border-right: 1px solid $ge-border;
      }
    }
    .schedule-panel {
      background-color: themed('background-ge-dark');
    }

    .branch-scenario-section {
      justify-content: left;
    }
    .select-container {
      min-width: 240px;
    }
    .select-label,
    .label-light,
    .select__label {
      font-size: $small-header;
      color: $ge-gray-h3;
    }
    .scenario-header {
      > div {
        margin-right: 15px;
        &:last-of-type {
          margin-left: auto;
        }
      }
      .select-dropdown-section {
        flex-grow: 0;
      }
    }
    .priority-selector .radio-btn-group {
      margin: 8px 0 8px -5px;
    }
    .schedules {
      margin-top: 20px;
      .results-card {
        display: flex;
        align-items: center;
        .results-card-header .title {
          margin: 0;
          color: $white !important ;
        }
      }
      .schedules-card {
        .content {
          position: relative;
          width: 100%;
        }
        .sliding-panel {
          position: initial;
          .card {
            background-color: transparent;
            box-shadow: none;
            border: none;
          }
        }
      }
    }
    .asset-schedule-link {
      text-decoration: underline;
      font-style: italic;
      height: auto;
      justify-content: left;
      color: $ge-link;
      &:disabled {
        color: #cccccc;
        cursor: not-allowed;
      }
    }

    .asset-row {
      border-bottom: 1px solid;
      padding: 10px 0;
    }
    .section-divider,
    .asset-row {
      border-color: themed('border-light');
    }
    .number-input__input {
      width: 100%;
    }
    .section-title {
      border-bottom: 1px solid themed('border-light');
      margin: 20px 0 10px 0;
      .custom-btn {
        margin-right: 30px;
        margin-bottom: 5px;
      }
      .custom-btn.text-btn {
        height: auto;
        color: $white;
      }
    }
    .seasonal-ratings {
      .results-card {
        margin-bottom: 0;
      }
      .asset-btn {
        justify-content: flex-start;
        padding: 5px 15px;
        &.active {
          background-color: themed('background-ge-text-box');
          border-radius: 0;
          font-weight: 600;
        }
      }

      .header .month-ranges {
        border-radius: 4px;
        text-align: center;
        .calendar-picker-container {
          border: none;
          padding: 0;
          background: themed('background-ge-text-box');
          .text-btn {
            color: #fff;
            height: 25px;
            padding: 0 10px;
          }
        }
      }
      .asset-list {
        min-height: 30px;
        align-items: center;
        div {
          text-align: center;
          width: 85%;
        }
      }
      .asset-lists-items {
        overflow-y: auto;
        height: 53vh;
        width: 100%;
        position: relative;
        &-scrollable {
          height: 100%;
          overflow-y: auto;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .asset-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100px;
          overflow: hidden;
        }
      }
      .updated-rating {
        .number-input-container {
          .number-input__input,
          .number-input-unit {
            font-weight: 600;
            color: themed('text-new');
          }
        }
      }
      .all {
        .number-input-container {
          .number-input__input,
          .number-input-unit {
            color: themed('text-white');
          }
        }
      }
      .number-input-container {
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        .number-input__input {
          width: 35px;
          border-bottom: none;
        }
        .number-input-unit {
          padding: 0 2px;
          width: 0;
        }
        .number-input__input,
        .number-input-unit {
          color: themed(' text-white');
          font-size: 14px;
        }
      }
      .modal-header {
        text-transform: none;
      }
      .ratings-modal-body {
        .radio-btn-group {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .option-group {
            margin: 3px 0;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  &.dark {
    .modal-body-wrapper .results-card {
      border: 1px solid $darker-gray;
    }
  }
  .schedule-details {
    .text-bold {
      font-size: $article-text;
    }
    &-card {
      .icon-btn-container {
        margin: 20px 5px 0;
      }
    }
  }
  .dark.seasonal-ratings .header .month-ranges {
    .calendar-picker-container .text-btn {
      background-color: #404040;
    }
  }

  .scenario-select {
    width: 240px;
  }
  .asset-schedules {
    .overwrite-schedule-section {
      margin-left: 5px;
    }
  }
}
.flatpickr-dark {
  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background-color: $darker-gray;
  }
  .flatpickr-calendar.hasTime .flatpickr-time {
    border: none;
  }
}
