@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.allocation-section {
  .content {
    padding: 5px 0;
  }
}

.allocation-header-content {
  display: flex;
  width: 100%;
  height: 48px;
  .header-name {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $ge-gray-h3;
  }
  .save-icon-container {
    display: flex;
    position: relative;
    left: 111px;
    align-items: center;
  }
}
.load-generation {
  margin-top: 8px;
  width: 100%;
  border-radius: 4px;
  background-color: $ge-dark;
  .allocation-contents {
    width: 100%;
    transition: 1s ease max-height, visibility 0s linear 0.3s, opacity 0.3s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: $white;
    max-height: 1000px;
    opacity: 1;
    @include applyTheme($themes) {
      color: themed('text-white');
    }

    .allocation-settings {
      display: flex;
      justify-content: space-between;
      &-col {
        width: 350px;
      }
    }

    .allocation-header {
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      outline: none;
      padding: 0;
      width: 100%;
      h2 {
        font-size: $body-text;
        font-weight: normal;
        margin: 5px 0;
      }
    }

    .section-header {
      font-size: 12px;
      color: $ge-gray-h3;
      font-weight: normal;
      margin: 0;
    }

    .allocation-settings {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .allocation-cell-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 8px;
      }

      .allocation-cell-row:first-child {
        background-color: transparent;
      }

      .allocation-cell-row:nth-child(1n + 2) {
        background-color: $ge-gray-blue;
        padding: 4px 0;
      }

      .allocation-cell {
        align-items: self-start;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        height: 16px;
        justify-content: center;
        width: 45px;
        overflow: hidden;
        &.unit {
          color: #54595e;
        }
        &-header {
          background-color: transparent;

          margin: 0px;

          font-size: 12px;
          @include applyTheme($themes) {
            background-color: transparent;
          }
        }

        &-label {
          width: 45px;
          flex-shrink: 0;
        }

        &--error {
          color: $warning-red;
          height: 68px;
          .allocation-input {
            border-color: $warning-red;
            outline: none;
            margin-bottom: 5px;
          }
        }
        &__h3 {
          font-size: $caption-text;
          color: $ge-gray-h3;
          margin: 0;
        }
        .number-input__input {
          text-align: left;
          color: $white;
        }
        .light.panel.per-phase-row-input--editable.number-input-group .number-input__input {
          border-bottom: 1px solid $ge-border;
        }
      }
    }

    .allocation-cell-message {
      color: $warning-red;
      font-size: $caption-text;
      padding-top: 8px;
    }

    .allocation-row-label {
      color: #91a6ba;
      line-height: 16px;
      font-size: $white;
      align-self: flex-start;
      font-weight: normal;
    }

    .power-type-check-container,
    .power-factor-settings {
      width: 22%;
      line-height: 16px;
      height: 35px;
      margin-top: 10px;
    }

    .power-type-checkbox {
      background-color: transparent;
      border: none;
      margin: 5px 0;
      &:first-of-type {
        margin-right: 25px;
      }
    }

    .power-type-label {
      display: inline-block;
      padding-left: 10px;
      @include applyTheme($themes) {
        color: themed('text-white');
      }
    }

    .allocation-submit {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      margin-top: 16px;
      width: 100%;
      border-top: 0.5px solid $ge-border;
      padding: 10px 0;

      .show-phase-group {
        color: $white;
        font-size: $caption-text;
      }

      .radio-btn-group .radio-btn-input {
        &:focus + .option-label-container label .radio-btn {
          box-shadow: none;
        }
      }
      .radio-btn-group {
        .radio-btn {
          &--selected .radio-btn-fill {
            background-color: $ge-blue !important;
          }
        }
      }
    }

    .allocation-select {
      .option-label-container {
        font-size: $caption-text;
        color: $white;
      }
    }

    .allocation-feedback {
      align-self: flex-start;

      height: auto;
      font-size: 12px;
      .allocation-success {
        color: $white;
        padding: 10px 0;
      }
      .allocation-error {
        color: #e71865;
      }
    }
  }
}
