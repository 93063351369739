@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.results-section {
  padding: 0;
  height: initial !important;

  h4 {
    margin: 5px 0 10px 0;
    font-weight: 700;
    font-size: 13px;
    color: $ge-gray-h3;
  }

  .results-legend {
    display: flex;
    flex-direction: column;

    &-label {
      display: inline-block;
      width: 75px;
    }

    &-item {
      font-size: 10px;
      font-style: italic;
    }
  }
}
