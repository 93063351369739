@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.non-expandable-section {
  .title {
    display: flex;
    padding: 5px 0;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .aux-content {
      text-align: right;
    }
  }
}

.non-expandable-section.dark {
  .title > p {
    color: $light-gray;
  }
}
