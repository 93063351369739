@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';
@import '~react-virtualized-select/node_modules/react-select/dist/react-select.min.css';

.Select.mode-select.virtualized-select:hover,
.Select.mode-select.virtualized-select.is-focused {
  @include applyTheme($themes) {
    background-color: themed('background-dark');
  }
  .Select-control {
    @include applyTheme($themes) {
      border: 1px solid themed('contrast-light');
    }
  }
}
.virtualized-select {
  .Select-control {
    background-color: transparent;
    border-radius: 4px !important;
    height: 32px;
    cursor: default !important;
    box-shadow: none !important;
    @include applyTheme($themes) {
      border: 1px solid themed('border-neutral');
    }
    .Select-value {
      @include applyTheme($themes) {
        background-color: themed('background-dark');
      }
      .Select-value-label {
        @include applyTheme($themes) {
          color: themed('text-header') !important;
        }
      }
    }
  }
  .Select-multi-value-wrapper {
    height: 30px;
  }
  .Select-menu-outer {
    margin: 3px 0;
    max-height: 230px;
    border-radius: 4px;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      border: 1px solid themed('text-disabled');
      color: themed('text-header');
    }
    .Select-menu {
      border-radius: 4px;
      padding: 4px 0;
      max-height: 210px;
    }
  }
  .VirtualizedSelectOption {
    padding: 8px 12px 8px 40px;
    height: 40px !important;
  }
  .VirtualizedSelectFocusedOption {
    background-color: $light-teal;
  }
  .ReactVirtualized__Grid {
    padding: 3px 0;
  }
  .VirtualizedSelectSelectedOption {
    font-weight: normal;
    background-color: initial;
  }
  .VirtualizedSelectSelectedOption:before {
    font-family: 'Material Icons';
    content: 'check';
    position: absolute;
    left: 15px;
  }
  .Select-arrow {
    border: 0 !important;
    top: 0 !important;
  }
  .Select-arrow:before {
    font-family: 'Material Icons';
    content: 'keyboard_arrow_down';
    font-size: 24px;
    top: -23px;
    position: absolute;
    right: -11px;
    @include applyTheme($themes) {
      color: themed('text-header') !important;
    }
  }
  .Select--single > .Select-control .Select-value,
  .Select-placeholder {
    line-height: 32px;
  }
}
.dark .Select-menu-outer {
  box-shadow: 0 4px 8px -2px #000, 0 19px 38px -2px #000;
  border: none !important;
}
