@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.zoom-controls {
  position: absolute;
  z-index: 1;

  &__button {
    @extend .flex-centered;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
    cursor: pointer;
    height: 40px;
    margin-top: 2px;
    overflow: hidden;
    padding: 0;
    width: 40px;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      color: themed('text-main');
      &:hover {
        background-color: themed('background-dark') !important;
      }
    }

    .icon-btn {
      height: 100%;
      width: 100%;
    }
  }
  &-top {
    border-radius: 5px 5px 0 0;
  }
  &-bottom {
    border-radius: 0 0 5px 5px;
  }

  .button-pair {
    margin: 10px 0;
  }

  .zoom-to-fit,
  .zoom-to-fit:hover {
    @extend .flex-centered;
    border-radius: 4px;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: $medium-charcoal;
    position: absolute;
    height: 40px;
    width: 40px;

    @include applyTheme($themes) {
      background-color: themed('background-dark');
    }

    .zoom-to-fit-btn {
      color: #959595;
      height: 40px;
      width: 40px;

      .icon-container {
        font-size: 34px;
      }

      &:hover {
        border-radius: 4px;
        @include applyTheme($themes) {
          background-color: themed('background-dark');
        }
      }
    }
  }
}
