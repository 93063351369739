@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.custom-btn {
  @extend .flex-centered;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  font-size: $body-text;
  height: 36px;
  overflow: hidden;
  padding: 0 12px;

  .btn-loading {
    margin-left: 10px;
  }

  &.primary-btn {
    background-color: $ge-blue;
    &:hover {
      background-color: rgba($ge-blue, 0.5);
    }
  }

  &.secondary-btn {
    background-color: transparent;
    border: 1px solid $ge-border;
    color: $white;
    &:hover {
      background-color: $ge-blue;
    }
  }

  &.text-btn,
  &.text-blue-btn {
    background-color: transparent;
    color: $ge-blue;
    font-size: $body-text;
    padding: 0;
    &:disabled {
      cursor: default;
      color: #cccccc;
    }
  }
  &.text-blue-btn {
    color: #0337a4;
  }
  &.tour-btn {
    background-color: white;
    color: #1675eb;
    &:hover {
      background-color: #f5f5f5;
    }
  }
  &.info-btn {
    color: $white !important;
    background: $ge-blue;
    font-weight: 600;
    &:hover {
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    }
  }
  &.hollow-btn {
    color: $white !important;
    background: transparent;
    border: 1px solid $ge-blue;
    font-weight: 600;
    &:hover {
      box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.3);
    }
  }
  &.primary-btn,
  &.secondary-btn,
  &.tour-btn,
  &.info-btn {
    &:disabled,
    &:disabled:hover,
    &:disabled:focus {
      border: none;
      cursor: default;
      background-color: #e3e3e3;
      color: #aaaaaa;
    }
  }

  &.circular-type {
    padding: 0.5em;
    border-radius: 50%;
  }
  .arrow_down {
    margin-left: 10px;
  }
}
