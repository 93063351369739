@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.instanceinfo-select-item {
  display: flex;
  flex-direction: horizontal;
  flex-align: center;
  justify-content: space-between;
}

.instanceinfo-select-item-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
