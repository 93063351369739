@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.der-asset-selector {
  .selected-der-row .expandable-section {
    margin-bottom: 10px;
    font-size: 12px;
    .header {
      padding: 6px 10px;
      border: 1px solid $ge-border;
      margin-bottom: 0;
      font-weight: 500;
      .toggle-content {
        width: auto;
        height: auto;
      }
      .header-content {
        width: 96%;
      }
      &:hover {
        color: initial;
      }
    }
    .content-container--active {
      min-height: 37px;
      border: 1px solid $ge-border;
      .content {
        padding: 0;
      }
    }
    .asset-type-row {
      justify-content: space-between;
    }
    .asset-row {
      padding: 10px;
      border-bottom: 1px solid $ge-border;
      &:nth-child(even) {
        background-color: $ge-dark;
      }
    }
    .der-asset-count {
      font-weight: normal;
    }
  }
}
