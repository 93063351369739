@import 'font-sizes';

$themes: (
  light: (
    background-very-dark: #ededed,
    background-dark: #1e1e1e,
    // background-dark: #ffffff,
    background-neutral: #f5f5f5,
    background-light: #ececeb,
    background-very-light: darken(#fefefe, 12%),
    contrast-neutral: $medium-teal,
    contrast-light: $light-teal,
    contrast-dark: $dark-teal,
    text-main: #ffffff,
    text-contrast: #262626,
    text-disabled: #cccccc,
    text-dark: #a0a0a0,
    text-header: #262626,
    border-neutral: $light-gray,
    border-light: #d4dfeb,
    label-light: #95a6b8,
    text-body: #ffffff,
    contrast-border: #ecf3fa,
    skeleton-light: #f1f1f1,
    // new theming options
    background-main-new: $lighter-blue,
    contrast-neutral-new: $white,
    text-new: #000,
    text-header-new: #95a6b8,
    secondary-button-text-new: $solid-blue,
    button-shadow-new: rgba(0, 0, 0, 0.12),
    skeleton-light-new: #f1f1f1,
    primary-icon-foreground: $primary-white,
    primary-icon-background: $primary-highlights,
    secondary-icon-foreground: $secondary-overlay,
    secondary-icon-background: $secondary-background,
    disabled-icon-foreground: $secondary-caption,
    disabled-icon-background: $secondary-overlay,
    contrast-background: #f1f5f7,
    bakground-very-light: #f7fafc,
    bakground-blue-light: #ecf3fa,
    background-white: #ffffff,
    text-white: #ffffff,
    background-ge-blue: $ge-blue,
    background-ge-dark: $ge-dark,
    text-ge: $primary-white,
    border-ge: $ge-border,
    ge-link: $ge-link,
    background-ge-text-box: $ge-gray-blue,
    color-h3-ge: $ge-gray-h3,
    background-hover-ge: rgba($ge-blue, 0.6),
    background-transparent: transparent,
  ),
  dark: (
    background-very-dark: $panel-black,
    background-dark: $dark-charcoal,
    background-neutral: #404040,
    background-light: $dark-gray,
    background-very-light: #a0a0a0,
    contrast-neutral: $medium-teal,
    contrast-light: $light-teal,
    contrast-dark: $dark-teal,
    text-main: $medium-gray,
    text-contrast: $white,
    text-disabled: #606060,
    text-dark: #949899,
    text-header: #ffffff,
    border-neutral: $dark-gray,
    border-light: #404040,
    label-light: #949899,
    text-body: $medium-gray,
    contrast-border: #606060,
    skeleton-light: #303030,
    // new theming options
    background-main-new: #141936,
    contrast-neutral-new: #222d4d,
    text-new: #fff,
    text-header-new: #fff,
    secondary-button-text-new: #fff,
    button-shadow-new: #222d4d,
    skeleton-light-new: #222d4d,
    primary-icon-foreground: $white,
    primary-icon-background: transparent,
    secondary-icon-foreground: $black,
    secondary-icon-background: transparent,
    disabled-icon-foreground: #aaaaaa,
    disabled-icon-background: #e3e3e3,
    contrast-background: #1b1b1b,
    bakground-very-light: #303030,
    bakground-blue-light: #303030,
  ),
  red-contrast: (
    contrast-neutral: #ff4136,
    contrast-light: lighten(#ff4136, 20%),
    contrast-dark: darken(#ff4136, 20%),
  ),
);

@mixin applyTheme($themes) {
  @each $theme, $map in $themes {
    // Create a theme class the will proceed the class it is nested in
    .#{$theme} & {
      $theme-map: () !global;
      // Take each key from inside the include and extract the theme value
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

/**
Allows you to use SCSS functions with theme colors, even if all themes do not have color defined.
If you attempt to use a built in SCSS function like lighten with a color that does not
exist in the map, it will fail to compile. But if you call tryTheme('background-light', lighten, 10%)
this rule will only be compiled if background-light is defined.
This also applies to calling rgba with a themed color.
**/
@function tryTheme($key, $type, $arg, $prefix: null) {
  @if map-has-key($theme-map, $key) {
    @return $prefix call(get-function($type), themed($key), $arg);
  } @else {
    @return null;
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin applyFontTheme($font-size) {
  @each $theme, $map in $weights {
    // Create a theme class the will proceed the class it is nested in
    .#{$theme} & {
      font-weight: map-get(map-get($weights, $theme), $font-size);
      font-size: map-get($sizes, $font-size);
    }
  }
}

@mixin applySpecificFontTheme($font-size, $theme) {
  @if (map-has-key($weights, $theme)) {
    font-weight: map-get(map-get($weights, $theme), $font-size);
  }
  font-size: map-get($sizes, $font-size);
}
