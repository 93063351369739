@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 100%;
  margin-top: 20px;
  .custom-btn {
    margin-left: 20px;
  }
}
