$success: #2fa70c;
$error: #cf3807;
$warning: #f3a200;
$info: #91a6ba;
$error-lighter-red: #d53831;

$white: #fff;
$white-20: rgba(255, 255, 255, 0.2);
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);

$off-white: #f7f7f7;

$very-light-teal: #50c7c2;
$light-teal: #06afa8;
$medium-teal: #0a7e79;
$dark-teal: #005753;

$teal-stripes: linear-gradient(
  45deg,
  $very-light-teal 0%,
  $very-light-teal 30%,
  $light-teal 30%,
  $light-teal 70%,
  $very-light-teal 70%
);
$teal-thin-stripes: repeating-linear-gradient(
  135deg,
  $medium-teal 0%,
  $medium-teal 2%,
  $light-teal 2%,
  $light-teal 4%
);

$ge-dark: #121f28;
$ge-blue: #007acc;
$ge-border: #23343f;
$ge-gray-blue: #425866;
$ge-gray-h3: #8492a6;
$ge-link: #77cdfc;
$ge-button-hover: rgba($ge-blue, 0.6);
$ge-error: #de5558;

$light-blue: #a3c7eb;
$pacific-blue: #009ddc;
$navy-blue: #0079c1;
$dark-blue: #5a9cde;
$darker-blue: #00184b;
$blue-gray: #8894af;
$solid-blue: #0337a4;
$gray-blue: #f1f5f7;
$lighter-blue: #ecf3fa;
$light-green: #c1f5c2;
$dark-green: #008000;
$grey-light: #54595e;
$light-red: #ffcccc;
$salmon: #fc7169;

$light-gray-border: #e0e0e0;
$lighter-gray: #a0a0a0;
$lighter-gray-opacity-70: rgba(160, 160, 160, 0.7);
$very-light-gray: #f1f2f2;
$light-gray: #d4d4d4;
$very-lighter-gray: #cccccc;
$medium-gray: #949899;
$medium-gray-90: #909090;
$dark-gray: #606060;
$dark-gray-30: #303030;
$darker-gray: #404040;
$transparent-gray: rgba(209, 209, 209, 0.3);

$medium-charcoal: #383838;
$dark-charcoal: #262626;

$panel-gray: #1b1b1b;
$panel-black: #151515;

$black: #000;
$black-10: rgba(0, 0, 0, 0.1);
$black-20: rgba(0, 0, 0, 0.2);
$black-60: rgba(0, 0, 0, 0.6);
$black-70: rgba(0, 0, 0, 0.7);

$warning-red: #ff3a00;

$load-highlight: rgba(255, 110, 110, 1);
$generation-highlight: rgba(122, 212, 0, 1);

$primary-highlights: #0337a4;
$primary-accent: #e71865;
$primary-white: #ffffff;
$primary-black: #000000;
$secondary-background: #f9fbfd;
$secondary-overlay: #ecf3fa;
$secondary-caption: #95a6b8;
$secondary-optional: #4f6990;
$graph-g1: #00396f;
$graph-g2: #0337a4;
$graph-g3: #2265de;
$graph-g4: #2785fc;
$graph-g5: #7792ce;
$graph-g6: #98c3ff;
$graph-g7: #cde0ff;
$graph-g8: #00b396;
$graph-g9: #77dac6;
$graph-g10: #a3decb;
$graph-g11: #d8edc5;
$indicator-positive: #2fa70c;
$indicator-negative: #cf3807;
$indicator-neutral: #f3a200;
$indicator-neutral2: #0337a4;
