@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';
.label-row {
  display: flex;
  align-items: center;
  width: calc(100% - 16px);

  .label-text {
    padding-left: 5px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
  }
}
.option-row {
  display: flex;
  width: 100%;
  .option-icon {
    display: flex;
    min-width: 15px;
    align-items: center;
    justify-content: flex-end;
  }
  svg {
    background: $medium-teal;
    border-radius: 10px;
  }
}
