@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.reports-section {
  background-color: $ge-dark;
  border-radius: 4px;
  margin-top: 8px;
  .reports-title {
    font-size: 13px;
    font-weight: 600;
    padding: 0px 15px;
    border-bottom: 1px solid $ge-border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    margin-top: 0;
    color: $ge-gray-h3;
  }
}

.reports-container {
  background-color: transparent;
  width: 375px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.pl {
  padding-left: 15px;
}

.pr {
  padding-right: 15px;
}

.ml {
  position: relative;
  left: 14px;
}

.mb {
  margin-bottom: 16px;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.report-title {
  height: 21px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 4px 0 0;

  .custom-btn.text-btn,
  .custom-btn.text-blue-btn {
    height: 16px;
    font-size: 12px;
    color: $ge-link;
  }
  .custom-btn.text-btn:disabled {
    color: #d4dfe8;
  }
}

.download {
  margin-left: -0.5px;
  padding-right: 4px;
}

.icon-holder {
  .download {
    div {
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.custom-btn.text-btn {
  .download {
    svg {
      color: $ge-link;
      stroke-width: 5px;
    }
  }
  &:disabled {
    .download {
      svg {
        color: #d4dfe8;
        stroke-width: 5px;
      }
    }
  }
}

.info svg {
  width: 14px;
  height: 14px;
}
