@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.cim-explorer {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  min-height: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* CIM EXPLORER PAGE CONTAINER */
  .cim-explorer-container {
    display: flex;
    flex-direction: row;
    min-height: 0;
    width: 100%;
    height: 100%;
    @include applyTheme($themes) {
      background-color: themed('background-very-dark');
    }

    .cim-explorer-contents {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }

  /* CIM EXPLORER PAGE HEADER */
  .cim-explorer-header {
    flex-shrink: 0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 0 10px 10px;
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }

    &__h1 {
      font-size: $headline-text;
      margin: 0;
    }
  }

  /* CIM EXPLORER PAGE CONTENTS */
  .cim-explorer-content {
    border-radius: 4px;
    flex-direction: column;
    display: flex;
    height: 100%;
    margin: 15px 20px;
    overflow: hidden;
    padding: 10px 5px;
    position: relative;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
    }

    &-main {
      width: 100%;
      display: flex;
      min-height: 0;
      height: 100%;
    }
  }

  /* CIM EXPLORER COLUMN STYLES */
  .cim-explorer-column {
    border: 1px solid $medium-gray;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    margin: 0 5px;
    width: 30%;
    @include applyTheme($themes) {
      border-color: themed('background-very-dark');
    }
  }

  // Style for the header. Has active (with content) & inactive (without content) styles.
  .cim-explorer-column-header {
    align-items: center;
    border-bottom: 1px solid $medium-gray;
    display: flex;
    flex: 0 0 60px;
    justify-content: flex-start;
    padding: 0 20px;
    @include applyTheme($themes) {
      border-color: themed('background-dark');
      color: themed('text-contrast');
    }

    &--active {
      @include applyTheme($themes) {
        background-color: themed('background-light');
      }
    }
    &--inactive {
      @include applyTheme($themes) {
        background-color: themed('background-very-light');
      }
    }
    &__h2 {
      display: inline-block;
      font-weight: normal;
      font-size: $title-text;
    }
  }

  .cim-tree-toggle-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cim-explorer-column-content {
    overflow-y: auto;
    overflow-x: auto;
    background-color: $white;
    color: $dark-charcoal;
    height: 60em;
    max-height: 60em;
    &__ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .loading-placeholder {
    @extend .flex-centered;
    flex-direction: column;
    color: $dark-gray;

    .loading-card {
      @extend .flex-centered;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      flex-direction: column;
      padding: 15px;

      i {
        margin-bottom: 10px;
      }
    }
  }

  /* CIM CLASS/INSTANCES */
  .instances-column {
    min-width: 35%;
  }

  .instances-view-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cim-types {
    color: $dark-charcoal;
    font-size: $body-text;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &:nth-child(even) {
      background-color: $white;
    }
    &:nth-child(odd) {
      background-color: $very-light-gray;
    }
    &__li {
      padding: 5px 0;
    }
    i {
      padding-right: 5px;
    }
    &__span {
      white-space: nowrap;
    }
    &__button {
      align-items: center;
      border: none;
      display: flex;
      margin: 0;
      padding: 5px;
      font: inherit;
      color: inherit;
      cursor: pointer;
      overflow: visible;
      background-color: transparent;
      width: 100%;
      text-align: left;
    }
    &--active:nth-child(even),
    &--active:nth-child(odd) {
      background-color: $light-teal;
      color: $white;
      &:hover {
        background-color: $light-teal;
        cursor: pointer;
      }
    }
    &--inactive {
      &:hover {
        background-color: $black-10;
        cursor: pointer;
      }
    }
  }

  .cim-types-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /* ANCESTRYTREE STYLES */
  .cim-tree {
    padding: 10px 20px;
    font-size: $body-text;

    .cim-types--active,
    .cim-types--active:hover {
      background-color: transparent;
      color: $light-teal;
    }
  }

  .cim-tree-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &__button {
      background-color: inherit;
      border: none;
      border-radius: 3px;
      cursor: pointer;
      font-size: $body-text;
      padding: 5px 7px 3px 7px;

      &:hover,
      &:focus,
      &--active {
        background-color: $medium-gray;
        @include applyTheme($themes) {
          background-color: themed('background-neutral');
        }
      }

      svg g rect {
        @include applyTheme($themes) {
          fill: themed('text-contrast');
        }
      }
    }
  }

  .cim-tree-icon {
    height: 35px;
    width: 35px;
    margin: 0 10px;
    background-color: transparent;
    border: none;
  }

  /* CIM Instance Styles */
  .cim-reference {
    font-size: $body-text;
    padding: 5px 15px;
    cursor: pointer;
  }

  /* CIM Reference List */
  .cim-reference {
    padding-top: 0;
    padding-bottom: 0;
    &-list {
      display: flex;
      flex-direction: column;
      padding: 5px 15px;
    }

    &-list-item {
      align-items: center;
      display: flex;
    }

    &-list-header {
      display: flex;
      align-items: center;
    }

    &__button {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: inherit;
      flex-grow: 1;
      font: inherit;
      margin: 0;
      overflow: visible;
      padding: 5px 0;
      text-align: left;
    }

    &-list-header,
    &-list-item,
    &__button {
      cursor: pointer;
      &:hover {
        color: $light-teal;
      }
    }

    &-list-item {
      display: flex;
      align-items: center;
    }

    .ref-name {
      display: inline-block;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0 3px;
    }
  }

  .icon {
    padding-right: 7px;
  }

  /* CIM Atributes Styles */

  .cim-attributes-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    &__button {
      color: $white;
      padding: 5px 7px 3px 7px;
      font-size: $body-text;
      border: none;
      cursor: pointer;
      border-radius: 3px;
      background-color: transparent;
      @include applyTheme($themes) {
        svg g path {
          fill: themed('text-contrast');
        }
      }
    }
  }

  .attributes-column {
    .cim-attributes-container {
      border-bottom: 1px solid $medium-gray;
      @include applyTheme($themes) {
        border-color: themed('background-dark');
      }
    }
    .cim-explorer-column-header--active .cim-attributes-controls__button {
      @include applyTheme($themes) {
        svg g path {
          fill: themed('text-main');
        }

        &--show-empty svg g path {
          fill: themed('contrast-neutral');
        }
      }
    }
  }

  .cim-attributes {
    display: flex;
    padding: 5px;
    .icon {
      padding-top: 2px;
    }

    &__p {
      display: inline-block;
      font-size: $body-text;
      line-height: 24px;
      margin: 0 0 0 5px;
    }
  }

  .cim-references-container,
  .cim-attributes-container {
    height: 42%;
    background-color: $white;
    overflow: auto;
  }

  @media (max-height: 800px) {
    .cim-explorer-header {
      max-height: 9vh;
    }
  }
}
