$icon-size: 24px;
$large-header: 24px;
$headline-text: 22px;
$title-text: 18px;
$body-text: 15px;
$article-text: 12px;
$caption-text: 12px;
$small-icon-size: 14px;
$small-header: 14px;

$weights: (
  light: (
    caption-text: 400,
    body-text: 400,
    title-text: 500,
    article-text: 400,
    headline-text: 500,
  ),
  dark: (
    caption-text: 400,
    body-text: 400,
    article-text: 400,
    title-text: 400,
    headline-text: 400,
  ),
);

$sizes: (
  caption-text: 12px,
  body-text: 15px,
  title-text: 18px,
  article-text: 12px,
  headline-text: 22px,
  small-icon-size: 14px,
  small-header: 14px,
  large-header: 24px,
);
