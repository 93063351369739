@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.activity-modal {
  .modal-body {
    display: flex;
    padding: 0 !important;
    max-height: 96%;
    min-height: 60% !important;
    .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 20px;

      h2 {
        margin: 0;
        @include applyTheme($themes) {
          color: themed('text-header');
        }
      }
    }
    .tip-span {
      display: table;
    }

    @extend .flex-centered;
    .node-option {
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-right: none;
      border-left: none;
      display: grid;
      display: -ms-grid;
      grid-gap: 18px;
      padding: 15px 30px 15px 20px;
      @include applyTheme($themes) {
        border-color: themed('text-disabled');
      }
    }
    .node-header {
      font-weight: 500;
    }
    .valid-node-results,
    .node-header {
      grid-template-columns: 1fr 1fr 1fr 1.2fr 1fr;
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }
      .node-item:nth-of-type(6n + 0) {
        grid-column-start: 2;
      }
    }
    .invalid-node-results {
      grid-template-columns: 1fr 4.7fr;
      .node-option-invalid-result {
        font-size: 12px;
        text-align: left;
      }
    }
    .modal-message {
      overflow: auto;
    }
    .node-option-item-name {
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }
    }
    @mixin positioned-grid-column-row() {
      $elements: 20;
      @for $i from 1 to $elements {
        .node-item:nth-child(1),
        .node-item:nth-child(2),
        .node-item:nth-child(3) {
          -ms-grid-row: 1;
        }
      }
    }
    // Edge support to position grid
    @supports (-ms-ime-align: auto) {
      @include positioned-grid-column-row;
    }
    // IE support to position grid
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @include positioned-grid-column-row;
    }
  }
}
