@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

// Dashed underline style overrides (panel type)
.panel {
  &.per-phase-row-input,
  &.single-row-input {
    &.number-input-group,
    &.text-input-group {
      .number-input__input,
      .text-input__input {
        background-color: transparent;
        border: none;
        border-bottom: 1px dashed $dark-gray;
        border-radius: 0px;
        color: $medium-gray;
        display: flex;
        font-size: $caption-text;
        text-align: center;
        &:invalid {
          border-color: red;
        }

        &:disabled {
          background-color: transparent;
          border-bottom: 1px dashed transparent;
          cursor: default;
        }
      }

      .number-input__label,
      .text-input__label {
        font-size: $caption-text;
      }

      .input-error {
        margin-top: 4px;
        p {
          margin-right: 50px;
        }
      }
    }

    &--editable.number-input-group .number-input__input,
    &--editable.text-input-group .text-input__input {
      color: $white;
    }
    &--edited.number-input-group .number-input__input,
    &--edited.text-input-group .text-input__input {
      border-bottom: 1px solid $light-teal;
    }
    &--invalid.number-input-group .number-input__input,
    &--invalid.text-input-group .text-input__input {
      border-bottom: 1px solid $warning-red;
    }
  }

  &.per-phase-row-input {
    .number-input,
    .number-input-container,
    .text-input,
    .text-input-container {
      margin: 0;
      width: 100%;
    }

    &.number-input-group,
    &.text-input-group {
      margin: 0;
      &.dark {
        .number-input__input,
        .number-input__input:disabled,
        .text-input__input,
        .text-input__input:disabled {
          background-color: transparent;
        }
      }
    }

    .number-input__input,
    .text-input__input {
      align-items: center;
      height: 100%;
      justify-content: center;
      padding: 0px;
      width: 100%;
    }
  }

  &.single-row-input {
    color: $medium-gray;
    font-family: 'Roboto', sans-serif;
    margin: 5px 0;
    width: 100%;

    .number-input,
    .text-input {
      margin: 0;
      width: 100%;
      align-items: flex-end;
    }

    .number-input-container,
    .text-input-container {
      background-color: transparent;
      align-items: flex-end;
    }

    &.number-input-group,
    &.text-input-group {
      .number-input__input,
      .text-input__input {
        height: 20px;
        width: 125px;
        padding: 0;
      }
      .number-input__label,
      .text-input__label {
        padding-bottom: 4px;
      }

      .number-input-unit,
      .text-input-unit {
        background-color: transparent;
        width: 42px;
        color: $medium-gray;
        display: flex;
        padding-left: 10px;
        align-items: flex-end;
        height: 100%;
      }
      &.dark {
        .number-input__label,
        .text-input__label {
          color: $medium-gray;
        }
      }
    }

    &--editable.number-input-group,
    &--editable.text-input-group {
      .number-input__input,
      .text-input__input {
        border-bottom: 1px dashed $dark-gray;
      }
      .number-input__label,
      .text-input__label {
        padding-bottom: 0px;
      }
    }

    &--edited.number-input-group .number-input__input,
    &--edited.text-input-group .text-input__input {
      border-bottom: 1px solid $light-teal;
    }
    &--invalid.number-input-group .number-input__input,
    &--invalid.text-input-group .text-input__input {
      border-bottom: 1px solid $warning-red;
    }
  }
}

.light.panel {
  &.per-phase-row-input,
  &.single-row-input {
    &.number-input-group,
    &.text-input-group {
      .number-input__input,
      .text-input__input {
        color: #262626;
      }
    }

    &--editable.number-input-group .number-input__input,
    &--editable.text-input-group .text-input__input {
      color: #262626;
    }
  }

  &.single-row-input {
    color: #262626;

    &.number-input-group,
    &.text-input-group {
      .number-input-unit,
      .text-input-unit {
        color: #262626;
      }
    }
  }
}

.dark.panel {
  &.per-phase-row-input,
  &.single-row-input {
    &.number-input-group,
    &.text-input-group {
      .number-input__input,
      .text-input__input {
        color: $white;
      }
    }
  }

  &.single-row-input {
    color: $white;
  }
}
