@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.hamburger-menu {
  &-navbar {
    background-color: $ge-dark;
    opacity: 1;
    z-index: 10000;
    top: 0;
    position: absolute;
    height: 100vh;
    width: 250px;
    left: 0;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0 60px 0 0;
    padding: 30px 0 0 5px;
    transform: translatex(-100%);
    transition: transform 0.4s ease-in-out;
    border: 1px solid $ge-border;
    &.active {
      transform: translatex(0);
    }
  }
  &.active .overlay {
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1000;
    background-color: #000;
    opacity: 0.6;
  }
  .close-icon {
    margin-left: 8px;
  }
  .nav-links {
    margin-top: 20px;
    p {
      font-size: 13px;
      margin: 15px 0 15px 15px;
    }
    .custom-btn {
      font-size: 13px;
      color: $white;
      justify-content: left;
      width: 97%;
      padding-left: 15px;
      i {
        font-size: 20px;
        color: $white;
        margin-right: 5px;
      }
      &.active {
        color: #ffffff;
        background-color: $ge-gray-blue;
        font-weight: 600;
        i {
          color: $white;
        }
      }
    }
    &-sub {
      padding-left: 15px;
      .custom-btn i {
        font-size: 7px;
        margin-right: 10px;
      }
    }
  }
}
