@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.profile-container {
  flex-direction: column;
  background: radial-gradient($dark-charcoal 1px, transparent 13%) 0 0,
    radial-gradient($dark-charcoal 1px, transparent 13%) 22px 22px;
  background-color: $panel-black;
  background-size: 22px 22px;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  flex-basis: 100%;

  @include applyTheme($themes) {
    background: radial-gradient(themed('background-dark') 1px, transparent 13%) 0 0,
      radial-gradient(themed('background-dark') 1px, transparent 13%) 22px 22px;
    background-color: themed('background-very-dark');
    background-size: 22px 22px;
    color: themed('text-main');
  }

  .profile-card-container {
    @extend .flex-centered;
    width: 100%;
    height: 100%;
  }

  .card {
    width: 500px;
    height: 350px;
  }

  .profile-details {
    @extend .flex-centered;
    flex-grow: 1;
  }

  .profile-details-row {
    width: 100%;
    display: flex;
    height: 50px;
    align-items: center;
    &--left {
      width: 40%;
      display: flex;
    }
    &--right {
      width: 60%;
      display: flex;
    }
  }

  .show-api-key__btn {
    flex-grow: 1;
  }

  .copy-api-key {
    display: flex;
    flex-grow: 1;
    &__input {
      border-radius: 4px;
      padding: 2px 5px;
      flex-grow: 1;
      font-size: $body-text;
      height: 36px;

      @include applyTheme($themes) {
        background-color: themed('background-very-dark');
        border: 1px solid themed('background-light');
        color: themed('text-main');
      }
    }

    &__btn {
      width: 35px;
      height: 36px;
      cursor: pointer;
    }
  }
}
