@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.container-panel {
  height: 100%;
  min-width: 375px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .container-panel-header {
    padding: 0;
    background-color: $ge-dark;

    &--expanded {
      padding: 0 60px;
    }

    .header-btn {
      @extend .flex-centered;
      background-color: transparent;
      border: none;
      color: $medium-gray;
      cursor: pointer;
      flex-shrink: 0;
      font-size: $headline-text;
      height: 30px;
      width: 35px;
      line-height: normal;
      @include applyTheme($themes) {
        color: themed('text-main');
        .st1,
        #path_1_,
        #Rectangle_820,
        #ic_expand_close path {
          fill: themed('text-white');
        }

        &:hover {
          color: themed('contrast-light');
          .st1,
          #path_1_,
          #Rectangle_820,
          #ic_expand_close path {
            fill: themed('contrast-light');
          }
        }

        &:disabled,
        &:disabled:hover {
          color: themed('text-disabled');
          cursor: not-allowed;
          .st1,
          #path_1_,
          #Rectangle_820,
          #ic_expand_close path {
            fill: themed('text-disabled');
          }
        }
      }

      path {
        opacity: 0.9;
      }

      &.clear-asset {
        padding-top: 5px;
      }
    }

    .edit-container-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > input {
        width: 100%;
        font-size: 12px;
        margin: 0;
        line-height: 16px;
        color: $ge-link;
      }

      span {
        @extend .flex-centered;
      }

      .icon-btn,
      .icon-btn:hover {
        margin-left: 12px;
        background: transparent;
      }
    }
    .container-select-section {
      margin-top: 0px;
    }
  }

  .feeder-content-multiple {
    margin-top: -24px;
    display: flex;
    height: 100%;
    min-height: 0;
    .assoctiated-substation-container {
      margin-right: -2px;
      background-color: $ge-dark;
    }
  }
  .feeder-content-not-edit-mode {
    margin-top: 0px;
    display: flex;
    height: 100%;
    min-height: 0;
    .assoctiated-substation-container {
      margin-right: -2px;
      background-color: $ge-dark;
    }
  }
  .feeder-content-single {
    margin-top: 0;
    display: flex;
    height: 100%;
    min-height: 0;

    .assoctiated-substation-container {
      background-color: $ge-dark;
    }
  }

  .themed-tabs {
    width: 100%;
  }
  .delete-feeder-container {
    display: flex;
    height: 60px;
    background-color: $ge-dark;
    .icon-container {
      padding: 0 8px 0 15px;
      display: flex;
      align-items: center;
    }
  }

  .container-panel-tab-contents {
    padding: 0px 0px;
    background-color: $ge-dark;
    width: 97.4%;
    &--expanded {
      padding: 10px 60px;
    }
  }

  .select-row {
    align-items: center;
    display: flex;
    padding: 0px 0 10px 15px;
    width: 335px;

    label {
      font-size: 12px;
      min-width: 120px;
      padding-right: 12px;
      padding-left: 8px;
      color: $white;
    }
    span {
      color: $ge-link;
      font-size: 12px;
    }

    .substation-select {
      .custom-select__single-value {
        color: $white !important;
        font-size: 12px;
      }
    }
  }
}

.delete-substation-container {
  display: flex;
  height: 36px;
  background-color: $ge-dark;
  .icon-container {
    padding: 0 8px 0 15px;
  }
}

.feeder-tooltip {
  @extend .flex-centered;
  flex-direction: column;
  padding: 5px;
  &-header {
    color: $white !important;
    font-size: $body-text;
    margin-bottom: 5px;
  }
  &-contents {
    width: 100%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
