@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.network-import-panel {
  width: 100%;

  select {
    background: transparent;
    width: 100%;
    border-radius: 3px;
    height: 30px;
  }

  &.dark {
    select {
      border-color: #606060;
      font-size: 16px;
      font-family: Roboto;
      color: #ffffff;
      &:disabled {
        background: #606060;
      }
    }

    option {
      background: #4f4f4f;
    }
    option:checked {
      background: #2e615f;
    }
  }

  &.light {
    select {
      outline: 0px;
      border-color: #d4d4d4;
      color: #262626;

      &:disabled {
        background: #cccccc;
      }
    }

    option:checked {
      background: #06afa8;
    }
  }
}
