@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.map-tooltip {
  background-color: $ge-blue;
  color: $white;
  border-radius: 4px;
  font-family: sans-serif;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  z-index: 1001; // one higher than the slidinginfopanel

  &-header {
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-size: $title-text;
    width: 100%;
  }

  &-subtitle {
    font-size: $body-text;
    width: 100%;
  }

  .tooltip-results-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: monospace;
    font-size: 11px;
    margin-top: 10px;

    .header {
      height: 20px;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;
      width: 100%;
      display: flex;
      flex-direction: row;
    }

    .row {
      display: flex;
      border-bottom: 1px solid $white;
    }

    .column {
      width: 64px;
      overflow: hidden;
      padding-right: 5px;
      padding-left: 5px;
      border-left: 1px solid $white;
      text-align: right;

      &:first-child {
        text-align: left;
        width: 72px;
      }

      &:last-child {
        border-right: 1px solid $white;
      }
    }
  }
}
