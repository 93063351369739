@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.job {
  font-size: 14px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  .files-delete {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 5px;
  }
  .type-date-status {
    padding-bottom: 5px;
  }
}
