@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.modal {
  @extend .flex-centered;

  /* MODAL OVERLAY */
  .modal-overlay {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1000;
    // Show the modal
    &--active {
      pointer-events: auto;
    }
  }

  .modal-overlay--active {
    opacity: 0.7;
  }

  .modal-body-wrapper {
    @extend .flex-centered;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  /* MAIN MODAL BODY INCLUDING HEADER & FOOTER */
  .modal-body {
    border-radius: 5px;
    box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    overflow: hidden;
    padding: 20px;
    pointer-events: none;
    position: relative;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    transition: transform 0.5s, opacity 0.5s;
    z-index: 10000;
    max-height: 95%;
    &--active {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0);
    }
  }

  /* MODAL HEADER */
  .modal-header {
    @include applyFontTheme('color-h3-ge');
    align-items: center;
    border-radius: 5px 5px 0 0;
    color: $ge-gray-h3;
    display: flex;
    flex-shrink: 0;
    .subtitle {
      text-transform: none;
      font-size: 15px;
      font-weight: normal;
      margin-top: 10px;
    }
  }

  /* MODAL TEXT BODY */
  .modal-message {
    color: $medium-gray;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-size: $body-text;
    height: 100%;
    padding: 5px 0;
    width: 100%;
  }

  .scroll-body {
    overflow-y: auto;
  }

  /* MODAL FOOTER WITH BUTTONS */
  .modal-footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: flex-end;
    padding: 10px 0 0 0;
    width: 100%;

    &__btn {
      border: none;
      color: $white;
      cursor: pointer;
      min-width: 75px;
      text-transform: capitalize;
      &:last-of-type {
        margin-left: 12px;
      }
    }
  }

  .modal-body {
    background-color: $ge-dark;
  }

  .modal-header {
    @include applySpecificFontTheme('color-h3-ge', 'light');
    color: $ge-gray-h3;
  }

  .modal-message {
    color: $ge-gray-h3;
  }
  .modal-overlay--active {
    background-color: $lighter-gray;
  }
  &.default {
    .secondary-btn:not([disabled]) {
      color: $white;
      border: 1px solid $ge-blue;
    }
  }
  &.primary {
    .modal-body {
      background-color: $ge-dark;
    }
    .modal-footer {
      .primary-btn:not([disabled]),
      .primary-btn:hover:not([disabled]) {
        background-color: $ge-dark;
      }
    }
    .secondary-btn:not([disabled]) {
      color: $white;
      border: 1px solid $ge-blue;
    }
  }
}
