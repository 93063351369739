@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.edit-mode-ribbon {
  display: flex;
  flex-direction: column;
  padding: 0 7px;
  width: 100%;

  .ribbon-contents {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-grow: 1;
    .ribbon-buttons {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .icon-button {
    @extend .flex-centered;
    border: 1px solid $ge-border;
    cursor: pointer;
    flex-direction: column;
    height: 45px;
    margin-bottom: 10px;
    position: relative;
    width: 75px;
    text-align: center;
    @include applyTheme($themes) {
      border-color: themed('border-ge');
    }

    &:hover,
    &--active {
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
      }
      .asset-secondary {
        @include applyTheme($themes) {
          fill: themed('background-neutral');
        }
      }
    }

    &--active {
      .asset-background,
      .asset-main,
      .load-asset-icon {
        fill: $light-teal;
        @include applyTheme($themes) {
          fill: themed('contrast-light');
        }
      }

      .line-icon {
        stroke: $medium-teal;
        @include applyTheme($themes) {
          stroke: themed('contrast-light');
        }
      }
    }
    &:disabled,
    &:disabled:hover {
      cursor: default;
      background-color: transparent;
    }

    &-line {
      stroke-width: 4;
      @include applyTheme($themes) {
        stroke: themed('text-contrast');
      }

      &:disabled {
        stroke: $medium-gray;
        @include applyTheme($themes) {
          stroke: themed('text-disabled');
        }
      }
    }
  }
  .add-icon-container.bulk-asset-btn {
    min-width: fit-content;
    .add-icon-container {
      min-width: 200px;
    }
    .icon-button {
      width: auto;
      display: flex;
      flex-direction: row;
    }
    .icon-label {
      max-width: 200px;
      @include applyTheme($themes) {
        color: themed('text-contrast');
      }
    }
    .icon-img,
    .icon-img svg {
      min-width: 10px;
      max-width: 17px;
      margin-right: 10px;
      line,
      circle {
        @include applyTheme($themes) {
          stroke: themed('text-contrast');
        }
      }
    }
  }
  .add-icon-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 45px;
    max-width: 75px;
    margin: 10px 12px;

    &--disabled,
    &--disabled.bulk-asset-btn .icon-label {
      @include applyTheme($themes) {
        color: themed('text-disabled');
      }
    }
  }
  .bulk-edit-buttons {
    width: 400px;
    display: inline-flex;
  }
  .line-asset-icon {
    .icon-img,
    .icon-img svg {
      height: 25px;
      pointer-events: none;
    }
  }

  .icon-img,
  .icon-img svg {
    height: 30px;
    pointer-events: none;
    min-width: 45px;
    max-width: 75px;
    &--add-line,
    &--add-line svg,
    &--add-line svg rect {
      min-width: 20px;
      max-width: 35px;
      .asset-main {
        stroke: none;
      }
    }

    &--edit-line,
    &--edit-line svg {
      height: 25px;

      .asset-main {
        stroke: none;
      }
    }
  }

  .icon-img--disabled {
    .asset-background,
    .asset-main,
    .load-asset-icon {
      fill: #606060;
      @include applyTheme($themes) {
        fill: themed('text-disabled');
      }
    }
    .downstream-energy {
      stroke: #606060;
      @include applyTheme($themes) {
        stroke: themed('text-disabled');
      }
    }
  }

  .icon-label {
    font-size: $caption-text;
    max-width: 75px;
  }
  .btn-overlap-loading .loading-icon {
    position: absolute;
    top: 6px;
    left: 18px;
    .material-icons {
      color: inherit;
      font-size: 35px;
    }
  }
  .analysis-delete-warning {
    @extend .flex-centered;
    margin-top: 5px;
    justify-content: flex-start;

    p {
      margin-left: 5px;
    }
  }
}

.edit-mode-save-modal-contents {
  padding: 10px 0;

  .radio-btn {
    margin-left: 0;
  }

  .radio-btn-group {
    margin-top: 20px;
  }

  .option-group,
  .option-icon-group span {
    width: 100%;
  }

  .option-group .radio-btn {
    align-self: flex-start;
    margin-top: 5px;
  }

  .text-input {
    display: block;

    &-container {
      margin-top: 10px;
    }
  }
}

.leave-edit-mode-contents {
  padding: 5px 0;
  line-height: 21px;
}
.bulk-asset-modal {
  @include applyTheme($themes) {
    .flex-spaced-row {
      justify-content: flex-end;
      width: 200px;
      div {
        margin-right: 10px;
      }
    }
    .text-light {
      color: themed('label-light');
      font-size: $caption-text;
      margin-top: 5px;
    }
    .dark .results-card {
      background: $dark-gray-30;
    }
    .type-title {
      margin: 15px 0 8px;
      text-transform: capitalize;
    }
  }

  .select-dropdown-section {
    margin: 3px 0;

    .select__label {
      margin-top: 5px;
      margin-bottom: 3px;
    }
  }
}
.load-shifting-modal {
  .asset-name {
    min-width: 155px;
  }
  .asset-group,
  .controlmode-types {
    display: grid;
    // grid-gap: 5px;
    align-items: center;
    text-align: left;
    padding: 6px 0px 6px 15px;
    width: 100%;
  }
  .asset-group {
    grid-template-columns: 1fr 2fr;
  }
  .accordion-header {
    display: grid !important;
    grid-template-columns: 1fr 2fr;
  }
  .radio-btn-group {
    display: grid;
  }
  .modal-body {
    @include applyTheme($themes) {
      background-color: themed('background-dark') !important;
    }
  }
}
