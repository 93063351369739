@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.bulk-lifecycle-header {
  padding: 0 15px;
  font-size: 13px;
  font-weight: 600;
  @include applyTheme($themes) {
    color: themed('color-h3-ge');
  }
}

.bulk-lifecycle-section {
  padding: 0 15px;

  @include applyTheme($themes) {
    color: themed('text-white');
    a {
      color: themed('background-ge-blue');
      text-decoration: underline;
    }
    .warning {
      margin: 10px 0;
      font-size: 13px;
      color: $warning-red;
    }
  }
}
