@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.node-display-container {
  display: flex;
  justify-content: space-between;

  .swap-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    .display-row {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      text-align: center;
      width: 235px;
      margin-left: 10px;
      height: 23px;
      button {
        text-align: center;
        text-decoration: none;
      }
      .between-nodes {
        padding: 0 10px;
      }
    }
  }

  .list-value-row-label {
    flex-shrink: 0;
    line-height: 24px;
  }

  .icon-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &-icon {
    margin-left: 10px;
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }

  &-node {
    margin-top: 2px;
    line-height: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }
}
