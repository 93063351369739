@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.power-transformmer {
  .expandable-section .content {
    padding: 0;
  }

  .node-display-container {
    margin: 5px 5px 5px 0px;
    flex-shrink: 0;
  }

  .tap-changer-button {
    width: 355px;
    font-size: $title-text;
  }
}
