@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.results-violations-panel {
  width: 100%;
  height: 100%;
  padding: 10px;

  .load-message,
  .error-message {
    padding-left: 10px;
  }
}
