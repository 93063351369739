@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.import-wizard-nav {
  align-items: center;
  display: flex;
  height: 50px;
  width: 300px;

  @include applyTheme($themes) {
    color: themed('text-contrast');
  }

  &-step {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-bottom: 6px;
    padding: 12px;
    position: relative;
    text-align: center;
    width: 180px;
    min-width: 180px;

    @include applyTheme($themes) {
      // This moves the chevrons apart
      border-right: 10px solid themed('background-dark');
    }

    &:before {
      border-bottom: 2px solid $light-teal;
      border-left: 2px solid $light-teal;
      border-right: 2px solid $light-teal;
      box-sizing: border-box;
      content: '';
      height: 50%;
      left: 0;
      position: absolute;
      top: 50%;
      transform: skew(-45deg, 0deg);
      width: 100%;

      @include applyTheme($themes) {
        background-color: themed('background-dark');
      }
    }

    &:after {
      border-left: 2px solid $light-teal;
      border-right: 2px solid $light-teal;
      border-top: 2px solid $light-teal;
      box-sizing: border-box;
      content: '';
      height: 50%;
      position: absolute;
      right: 0;
      top: 0;
      transform: skew(45deg, 0deg);
      width: 100%;

      @include applyTheme($themes) {
        background-color: themed('background-dark');
      }
    }

    &--active {
      &:before {
        background: $light-teal !important;
      }
      &:after {
        background: $light-teal !important;
      }
    }
  }

  .nav-text {
    z-index: 1;
  }

  .import-nav-link {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
