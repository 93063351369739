@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.expandable-section {
  display: flex;
  width: 100%;
  flex-direction: column;

  .header {
    -webkit-appearance: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: none;
    &.clickable {
      cursor: pointer;
    }
    &:disabled {
      color: $medium-gray;
      cursor: not-allowed;
    }
    &.default-header {
      .material-icons {
        font-size: 18px;
      }
    }
  }

  .add-remove-icon-header {
    font-size: 13px;
    color: #ffffff;
    font-weight: bold;
    height: 48px;
    padding: 1px 15px;
    &:hover {
      color: #ffffff;
    }
    .toggle {
      svg {
        color: #959595;
        height: 20px;
      }

      &:hover i {
        color: inherit;
      }
    }
    .header-content {
      color: $ge-gray-h3;
    }
    .toggle-content {
      color: #959595;
      position: relative;
      bottom: 13px;
      cursor: pointer;
    }
  }
  .default-header {
    &:hover {
      color: $ge-gray-h3;
    }
    .toggle {
      @extend .flex-centered;
      width: 100%;
      background-color: transparent;
      border: none;
      text-align: center;
      &:hover i {
        color: $ge-gray-h3;
      }
    }
    .header-content {
      line-height: 24px;
      color: $ge-gray-h3;
    }
    .toggle-content {
      color: inherit;
    }
  }

  .header-content {
    width: 90%;
    text-align: left;
  }

  .toggle-content {
    user-select: none;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    background: transparent;
    border: none;
  }

  .content-container {
    height: auto;
    max-height: 1px;
    min-height: 1px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: 1s ease max-height, visibility 0s linear 0.5s, opacity 0.5s;
    &--active {
      min-height: 2px;
      max-height: 4000px;
      height: auto;
      opacity: 1;
      visibility: visible;
      overflow: visible;
      width: 100%;
      .content-top {
        margin-top: 8px;
      }
    }
    &.content-container-border {
      border-top: 1px solid $ge-border;
    }
  }

  .content {
    padding: 5px 10px 5px 15px;
    &--scroll {
      max-height: 245px;
      overflow-y: auto;
    }
  }
}

.expandable-section.disable-animation {
  .content-container {
    transition: none !important;
  }
}
