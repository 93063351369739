// CustomChart Custom Tooltip Style
.rechart-custom-tooltip {
  background-color: $white;
  padding: 10px;
  color: $panel-black;
  border: 1px solid #ccc;
}

// Creates a custom dark theme for the flatpickr calendar
.flatpickr-dark {
  background-color: $medium-charcoal;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);

  // Triangle at top of calendar pointing at input
  &.arrowTop:before,
  &.arrowTop:after {
    border-bottom-color: $medium-charcoal;
  }

  // Month selector arrow styles
  .flatpickr-months .flatpickr-next-month svg path,
  .flatpickr-months .flatpickr-prev-month svg path {
    fill: $white;
  }

  // Font color for all text
  .flatpickr-current-month,
  .flatpickr-weekday,
  .flatpickr-day,
  .flatpickr-am-pm,
  .flatpickr-time input.flatpickr-hour,
  .flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
    color: $white;

    // Disabled date styles
    &.disabled {
      color: $dark-gray;
      &:hover {
        background: transparent;
        border-color: transparent;
      }
    }
  }

  // Font color for dates in other months
  .prevMonthDay,
  .nextMonthDay {
    color: $medium-gray;
  }

  // Input arrow styles
  .flatpickr-current-month .numInputWrapper span.arrowUp:after,
  .numInputWrapper span.arrowUp:after {
    border-bottom-color: $white;
  }

  .flatpickr-current-month .numInputWrapper span.arrowDown:after,
  .numInputWrapper span.arrowDown:after {
    border-top-color: $white;
  }

  .numInputWrapper span:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  // Hover and focus styles for date and AM/PM
  .flatpickr-day:hover,
  .flatpickr-am-pm:focus,
  .flatpickr-time .flatpickr-am-pm:hover {
    background: $light-teal;
    border-color: $light-teal;
  }

  .flatpickr-am-pm:hover {
    border-radius: 0 0 5px 0;
  }

  // Style for selected date
  .selected {
    background: $medium-teal;
    border-color: $medium-teal;
  }

  // Border between calendar and time picker
  &.showTimeInput.hasTime .flatpickr-time {
    border-color: $dark-gray;
  }
}

// Creates a custom dark theme for the flatpickr calendar
.flatpickr-light {
  // Hover and focus styles for date and AM/PM
  .flatpickr-day:hover,
  .flatpickr-am-pm:focus,
  .flatpickr-time .flatpickr-am-pm:hover {
    background: $light-teal;
    border-color: $light-teal;
    color: $white;
  }

  .flatpickr-am-pm:hover {
    border-radius: 0 0 5px 0;
  }

  // Style for selected date
  .selected {
    background: $medium-teal;
    border-color: $medium-teal;
  }
}
