@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.create-device-panel {
  @include applyTheme($themes) {
    align-items: flex-start;
    color: themed('text-main');
    padding: 0 30px;
    height: 100%;
    .heading {
      font-weight: bold;
    }

    .heading,
    .subheading {
      margin-bottom: 10px;
    }

    .options {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .radio-btn-group {
      align-items: initial;
      flex-direction: column;
      .radio-btn {
        margin-left: 0px;
      }
      .option-group {
        margin-bottom: 10px;
      }
    }

    .submit {
      margin-top: 20px;
      float: right;
      position: absolute;
      bottom: 20px;
      right: 20px;
    }

    .close {
      :hover {
        background: transparent;
      }
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }
}
