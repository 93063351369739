@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.permission-disabled-tip {
  .tooltip {
    width: 100%;
  }
  border-radius: 3px;

  h3 {
    margin: 0;
    text-decoration: underline;
    text-decoration-color: $light-teal;
  }

  text-align: left;
  padding: 5px 5px 5px 10px;
  pointer-events: none;
}
