@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.select-badge {
  display: flex;
  justify-content: space-between;

  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .multibadge-container {
    display: flex;
    margin-left: 10px;

    .select-badge-badge,
    .select-badge-badge-hover {
      @include applyTheme($themes) {
        border-color: themed('text-disabled');
      }
      min-height: 15px;
      border-width: 1px;
      border-style: solid;
      border-radius: 3px;
      padding: 0 4px 0 4px;
      font-size: 10px;
      display: flex;
      align-items: center;
    }

    .select-badge-badge:not(:first-child),
    .select-badge-badge-hover:not(:first-child) {
      margin-left: 4px;
    }

    .select-badge-badge-hover:hover {
      @include applyTheme($themes) {
        background-color: themed('background-light');
        border-color: themed('text-main');
      }
    }
  }
}
