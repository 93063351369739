@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import 'src/components/styles/_theme.scss';

.container-selection-container {
  margin-top: 10px;
  padding: 0 10px 0 10px;
  font-size: $caption-text;

  .container-selection-scroll {
    max-height: 175px;
  }
  .container-selection-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border-radius: 2px;

    .custom-checkbox {
      margin: 0 10px 0 0;
    }
    &:nth-child(odd) {
      @include applyTheme($themes) {
        background-color: themed('background-transparent');
      }
    }
  }

  .container-selection-label {
    text-align: start;
    margin-bottom: 5px;
  }
}
