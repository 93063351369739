@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.three-panel-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-header {
    background-color: $dark-charcoal;
    border-bottom: 1px solid $panel-black;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      border-color: themed('background-very-dark');
      color: themed('text-main');
    }
  }

  &-contents {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    min-height: 0;
    &.left-panel-expanded {
      .three-panel-layout-left {
        width: 300px;
      }
      .main-container {
        width: calc(100% - 300px);
      }
    }
    .main-container {
      display: flex;
      position: relative;
      width: 100%;
    }
  }

  &-left {
    display: flex;
    flex-shrink: 0;
    height: 100%;
    background-color: $dark-charcoal;
    z-index: 99; // One higher than the map and right panel
    width: 40px;
  }

  &-right {
    // box-shadow: -2px 1px 3px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    position: absolute;
    right: 0;
    z-index: 1;
    background-color: $ge-dark;
    top: -2px;

    &--inactive {
      padding: 0;
      width: 0px;
    }

    &--active {
      width: 375px;
      height: 100%;
      min-height: 0;
    }

    &--expanded {
      width: 100%;
    }
  }

  &-footer {
    border-top: 1px solid $ge-border;
    height: 90px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    position: relative;
    @include applyTheme($themes) {
      background-color: themed('background-ge-dark');
      border-color: themed('ge-border');
      color: themed('primary-white');
    }
  }
}
