@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../components/SlidingInfoPanel/AssetPanel/AssetPanel.scss';

.gis-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .mapboxgl-ctrl-group:not(:empty) {
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  .mapboxgl-ctrl-icon {
    height: 40px;
    width: 40px;
  }

  /* Over use to display messages over leaflet map */
  .gis-overlay {
    @extend .flex-centered;
    background: radial-gradient(#262626 1px, transparent 13%) 0 0,
      radial-gradient(#262626 1px, transparent 13%) 22px 22px;
    background-color: $panel-black;
    background-size: 22px 22px;
    color: $medium-gray;
    flex-direction: column;
    font-size: $headline-text;
    height: 100%;
    text-align: center;
    width: 100%;
    @include applyTheme($themes) {
      background: radial-gradient(themed('color-h3-ge') 1px, transparent 13%) 0 0,
        radial-gradient(themed('color-h3-ge') 1px, transparent 13%) 22px 22px;
      background-color: themed('background-ge-dark');
      background-size: 22px 22px;
      color: themed('color-h3-ge');
    }

    .gis-loading-message,
    .gis-error-message {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: $ge-gray-h3;

      .gis-loading-spinner {
        height: 150px;
        margin-top: 100px;
        color: $ge-blue;
      }
      .error-icon {
        font-size: 8em;
      }

      &.webgl-missing {
        .error-icon {
          font-size: 32px;
          color: red;
          padding-bottom: 10px;
        }

        font-size: 16px;
        line-height: 1.6;
      }
    }

    .overlay-message {
      font-size: $headline-text;
      text-align: center;
    }

    .gis-error-symbol {
      @extend .flex-centered;
      height: 275px;
      width: 275px;
      border: 3px dashed $dark-gray;
      border-radius: 300px;
      margin-bottom: 40px;
      font-size: 250px;
      color: $ge-gray-blue;
    }
    .gis-error-message {
      font-size: 30px;
      padding: 1em;
      width: 100%;
      color: $ge-error;
    }
  }

  .loading-overlay {
    align-items: flex-end;
    color: $ge-blue;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    left: 0;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
    @include applyTheme($themes) {
      color: themed('background-ge-blue');
    }
  }

  .map-container {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
  }

  .add-line-prompt {
    border-radius: 4px;
    left: 0;
    margin: auto;
    padding: 10px;
    pointer-events: none;
    position: absolute;
    right: 375px;
    text-align: center;
    top: 32px;
    width: fit-content;
    z-index: 10;
    @include applyTheme($themes) {
      background-color: themed('background-dark');
      color: themed('text-main');
    }
  }

  .information-tab {
    &:hover .information-icon {
      border-color: $light-gray;
    }

    .information-icon {
      padding-top: 1px;
      border: 2px solid $medium-gray;
      border-radius: 100px;
    }

    &--active:hover .information-icon,
    &--active .information-icon {
      @include applyTheme($themes) {
        border-color: themed('contrast-light');
      }
    }
  }

  .scenario-note {
    font-style: italic;
    margin-top: 5px;
  }

  .create-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 105px;
    width: 100%;
    padding: 10px 0;

    label {
      line-height: 40px;
    }

    .name-input {
      background-color: $panel-black;
      border: 1px solid $dark-gray;
      border-radius: 4px;
      color: $light-gray;
      font-size: $title-text;
      height: 30px;
      padding: 3px 5px;
      width: 100%;
      @include applyTheme($themes) {
        background-color: themed('background-very-dark');
        border-color: themed('text-disabled');
        color: themed('text-contrast');
        &:invalid {
          border-color: $warning-red;
        }
      }
    }

    .invalid-warning {
      color: $warning-red;
      font-size: $caption-text;
      margin-top: 5px;
    }
  }
}

.dark {
  .asset-main {
    fill: $white;
    stroke: none;
  }

  .asset-secondary {
    fill: #262626;
    stroke: none;
  }

  .shunt-connector,
  .link-connector,
  .downstream-energy {
    stroke: $white;
  }

  .connectivity-node {
    stroke: $white;
  }
}

.light {
  .asset-panel .circle-icon {
    stroke: $dark-charcoal;
    fill: $dark-charcoal;
    stroke-width: 1px;
  }

  .asset-main {
    fill: #262626;
  }

  .asset-secondary {
    fill: $white;
  }

  .shunt-connector,
  .link-connector,
  .downstream-energy {
    stroke: $medium-gray;
  }

  .connectivity-node {
    stroke: $dark-charcoal;
    fill: $dark-charcoal;
  }
}
