@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.error-page-container {
  @extend .flex-centered;
  height: 100%;
  width: 100%;
  background-color: $panel-black;

  .error-contents {
    align-items: center;
    background-color: $dark-charcoal;
    border-radius: 5px;
    color: $light-gray;
    display: flex;
    font-size: $body-text;
    flex-direction: column;
    height: auto;
    min-height: 325px;
    justify-content: space-around;
    padding: 15px;
    width: 600px;

    h2 {
      font-size: $headline-text;
    }
  }

  .home-btn {
    margin-top: 15px;
  }
}
