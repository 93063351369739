@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.settings {
  height: 100%;
  position: relative;
  width: 50px;

  .tip-span {
    height: 100%;
    width: 100%;
  }

  &__icon-light {
    background-image: url(/resources/images/settings_white.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  &__icon-dark {
    background-image: url(/resources/images/settings_white.svg);
    background-repeat: no-repeat;
    background-position: center;
  }

  &-container {
    background-color: #fafafa;
    box-shadow: 0 12px 17px 0 rgba(0, 0, 0, 0.5);
    color: #54595e;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-height: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50px;
    transition: 0.3s ease max-height, 0.2s ease padding;
    width: 218px;
    z-index: 2000;
    border-radius: 0 0 0 40px;
    &--active {
      max-height: 450px;
    }
    .settings-body {
      padding: 24px;
    }
  }

  &-tile {
    @extend .flex-column;
    flex-shrink: 0;
    font-size: $article-text;
    padding: 8px 0;
    align-items: flex-start;
    .settings-tour__btn {
      align-items: flex-start;
    }
  }

  &-tile-theme {
    font-size: $article-text;
    justify-content: center;
    padding: 8px 0;
    .radio-btn {
      margin-left: 0;
    }
    .radio-btn-group .option-label-container {
      color: #54595e;
    }
  }

  &-tile-title {
    font-size: $article-text;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
  }

  &-btn-container {
    width: 100%;
    padding: 0px 24px 0px 24px;
    height: 48px;
    &:after {
      content: '';
      display: block;
      position: relative;
      top: 0;
      left: 2.5%;
      height: 1px;
      width: 95%;
    }
  }

  &-link {
    border: none;
    width: 100%;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      font-weight: 600;
    }
  }

  &-logout__btn,
  &-tour__btn {
    border: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: $article-text;
  }

  // Override to match notifications
  .icon-btn-container.light .icon-container {
    color: $white !important;
  }

  &__btn {
    @extend .flex-centered;
    height: 100%;
    &.icon-btn {
      height: 100%;
      width: 100%;
      &:hover {
        background-color: transparent !important;
      }
    }

    &:hover,
    &--active,
    &:focus {
      outline: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &-version-container {
    @extend .flex-centered;
    flex-direction: column;
    padding: 0 23px 7px;
    width: 100%;
    flex-shrink: 0;
  }

  &-logo {
    height: 25px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 8px 0;
  }

  &-version {
    word-break: break-all;
    text-align: center;
    width: 100%;
    margin: 20px 0;
  }

  .toggleswitch {
    margin-top: 10px;
  }

  .settings {
    &-btn-container:after {
      background: #fafafa;
    }

    &-separator {
      height: 1px;
      width: 100%;
      background: #d2dde9;
      margin: 15px 0;
    }

    &-tile {
      background-color: transparent;
      &:hover {
        .settings-help-link,
        .settings-tour__btn {
          font-weight: 600;
        }
      }

      .settings {
        &-tour__btn,
        &-help-link {
          background-color: transparent;
          color: $dark-gray;
          padding: 0px;
        }
      }
    }

    &__btn {
      color: $white;
    }

    &-version-container {
      &--EQL {
        background: $medium-charcoal;
      }
      padding-top: 3px;
    }
  }
}
