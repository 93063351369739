@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.tip-span {
  &.primary {
    .help-icon {
      display: flex;
      align-items: center;
      font-size: 16px;
      @include applyTheme($themes) {
        color: themed('text-dark');
      }
    }
  }
}
