@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.icon-message {
  .react-toast-notifications__container,
  .react-toast-notifications__toast__countdown {
    min-width: 30px;
  }
  .react-toast-notifications__toast {
    display: flex;
    align-items: center;
    padding: 0 3px;
    border-radius: 4px;
  }
  .toast-message {
    display: flex;
    align-items: center;
  }
  .icon-btn-container .material-icons {
    color: #000;
  }
  .custom-btn,
  .icon-container {
    min-width: 30px;
    &:hover {
      background-color: initial !important;
    }
  }
}
