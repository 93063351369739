@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
/** LayerPanel Styles **/
.layer-panel {
  display: flex;
  font-family: 'Roboto', sans-serif;
  flex-direction: column;
  height: 100%;
  min-width: 375px;
  width: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    height: 100px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    padding: 5px 22px 14px 22px;
    width: 100%;
    &--expanded {
      padding: 14px 60px;
    }
    @include applyTheme($themes) {
      background-color: themed('background-ge-dark');
    }
    &-header {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      padding-bottom: 10px;
      &__h3 {
        color: $ge-gray-h3;
        font-weight: 700;
        font-size: 13px;
        margin: 0;
      }
    }
    .agg-options {
      display: flex;
      justify-content: flex-start;
      .custom-checkbox {
        margin-left: 0 !important;
      }
    }
    .custom-btn {
      color: $ge-link;
      font-size: 13px;
    }
  }

  .radio-btn {
    display: flex;
    border: 2px solid $ge-blue;
    border-radius: 18px;
    margin: 0 10px;
    height: 18px;
    width: 18px;

    @include applyTheme($themes) {
      &--selected {
        border-color: themed('background-ge-blue');
        .radio-btn-fill {
          background-color: themed('background-ge-blue');
        }
      }
    }
  }

  @keyframes showContents {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }
  .layer-expandable-section {
    .header {
      margin: 0;
    }

    .content-container {
      opacity: 1;
      overflow: hidden;
      transition: max-height 0.75s ease;
      &--active {
        min-height: 100%;
        overflow: visible;
        animation: showContents 0.85s;
      }

      .content {
        visibility: visible;
      }
    }
    &--expanded {
      padding: 0 35px;
    }
  }

  .radio-btn-select-group .option-icon-group {
    @include applyTheme($themes) {
      color: themed('text-main');
    }
  }

  .radio-btn-select-group .radio-btn-input:disabled + label {
    cursor: not-allowed;
    @include applyTheme($themes) {
      .option-icon-group {
        color: themed('text-disabled');
      }
      .radio-btn {
        border-color: themed('text-disabled');
      }
    }
  }

  .layer-selection-button {
    align-items: center;
    background: transparent;
    border: none;
    border-bottom: 1px solid $ge-border;
    cursor: pointer;
    display: flex;
    font-size: 12px;

    height: 62px;
    padding: 5px 0 5px 12px;
    width: 100%;
    @include applyTheme($themes) {
      border-bottom-color: themed('border-ge');
      color: themed('text-white');
    }
    &--expanded {
      padding: 5px 60px 5px 60px;
    }

    p {
      margin-left: 15px;
    }

    label {
      pointer-events: none;
    }

    .radio-btn-fill {
      border-radius: 15px;
      flex-grow: 1;
      margin: 2px;
    }

    .radio-btn-input {
      display: hidden;
      height: 0px;
      width: 0px;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;

      &:focus + label .radio-btn {
        box-shadow: 0px 0px 1px 10px rgba(41, 150, 136, 0.1);
      }
    }
  }

  .expandable-section {
    border-bottom: 1px solid $ge-border;
    @include applyTheme($themes) {
      border-bottom-color: themed('ge-border');
      color: themed('text-main');
    }

    .header {
      align-items: center;

      .layer-selection-button {
        border-bottom: none;
        height: 50px;
      }
    }
    .content {
      padding: 0;
    }
  }

  .selected-layer {
    border-bottom: none;
    box-shadow: 0px 0px 6px 3px $black-20;
    margin-top: -3px;
    z-index: 100;
  }

  .layer-section {
    margin-left: 50px;
    margin-top: 1px;
    border-top: 1px solid $ge-border;
    @include applyTheme($themes) {
      border-top-color: themed('border-ge');
      color: themed('text-main');
    }

    .layer-checkbox-group .layer-checkbox-row {
      margin-left: 0;
    }

    .Select-input {
      padding: 0;
    }
  }

  .current-section,
  .apparent-power-section {
    .toggle-section {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      margin-right: 15px;
      color: $ge-gray-h3;
      font-size: 12px;
      .toggleswitch.light .toggleswitch-checkbox:checked + .toggleswitch-label {
        border-color: transparent;
        background-color: $ge-blue;
      }
      .toggleswitch.light .toggleswitch-checkbox:checked + .toggleswitch-label:before {
        background-color: #fff;
        border-color: #fff;
      }
    }
    .selection-section {
      border-bottom: 1px solid $medium-gray;
      @include applyTheme($themes) {
        border-bottom-color: themed('background-dark');
      }

      &.layer-selection {
        .radio-btn-group {
          flex-wrap: wrap;

          .option-icon-group {
            @include applyTheme($themes) {
              color: themed('text-ge');
            }
          }
          .option-group {
            align-items: center;
            height: 35px;
            width: 50%;
            height: 35px;
            align-items: center;
          }
        }
      }
    }
    .radio-btn-group {
      .option-group .radio-select {
        width: 75px;
      }
      .option-group:first-of-type .radio-select {
        width: 120px;
      }
    }
  }

  .layer-phase-selector,
  .layer-unit-selector {
    padding: 10px 10px 10px 0;
    width: 325px;
  }

  .layer-phase-selector {
    .option-group {
      .Select {
        width: 115px;
      }
    }

    .option-icon-group span {
      width: 95px;
      color: $white;
      font-size: 12px;
    }
  }

  .radio-phase,
  .radio-mode {
    border-bottom: 1px solid #000;
    padding: 5px 0;
  }

  .hc-radio-mode .radio-btn-select-group .radio-select {
    width: 90px;
  }

  .layer-unit-selector {
    font-size: $body-text;
    border-bottom: 1px solid $medium-gray;
    border-top: 1px solid $medium-gray;
    @include applyTheme($themes) {
      border-bottom-color: themed('background-dark');
      border-top-color: themed('background-dark');
    }

    .radio-btn-select-group .radio-select {
      width: 105px;
    }
  }

  .layer-checkbox-area {
    border-bottom: black 1px solid;
    width: 100%;
    display: inline-flex;
  }

  .layer-checkbox-group {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    width: 305px;

    .layer-checkbox-row {
      padding: 7.5px 10px 7.5px 0;
      display: flex;
      justify-content: space-between;

      &:hover {
        .edit-icon-div .edit-icon {
          color: $medium-gray;
        }
      }

      .layer-checkbox-container {
        display: flex;
      }

      .layer-checkbox-label {
        padding-left: 15px;
        height: 22px;
        font-size: 12px;
        white-space: pre;
      }

      .check-container {
        border-width: 2px;
      }

      .edit-icon-div {
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:disabled {
          cursor: default;
          .edit-icon {
            color: transparent;
          }
        }

        .edit-icon {
          color: transparent;
          pointer-events: none;
        }
      }

      .edit-panel-container {
        position: relative;
        &.edit-active .edit-icon-div .edit-icon {
          color: $medium-gray;
        }
      }
    }
  }
  .radio-btn-group--column {
    padding: 10px 0px;
  }

  .radio-btn-group--row {
    padding: 10px 0px;
    width: 325px;
  }
}
