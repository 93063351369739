@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.left-rail-expandable-section {
  .left-rail-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    padding-left: 15px;
    span {
      padding-left: 15px;
    }
  }

  .expand-button-hover {
    background-color: transparent;
    border: none;
    outline: none;
  }

  .expand-button {
    border: none;
    background: transparent;
    color: $medium-gray;
    cursor: pointer;
    height: 100%;
    margin: 0;
    width: 100%;
    outline: none;
    padding: 0;
    &:disabled {
      p {
        color: $light-gray;
      }
    }
    &-contents {
      background-color: transparent;
      border: none;
      color: $medium-gray;
      outline: none;
      padding: 10px 15px;
      display: flex;
      justify-content: space-between;
      text-align: left;
      p {
        display: inline-block;
        font-size: $body-text;
        line-height: 24px;
      }
      &.active-tile {
        border: none;
        margin-left: 8px;
      }
    }
  }

  .tile-checkbox .check {
    border-color: $white;
  }

  .left-rail-title:hover,
  .left-rail-title:hover .expand-button-contents {
    background-color: #fafafa;
  }

  .expand-button-hover {
    background-color: #fff;
  }

  .expand-button {
    background: $teal-thin-stripes;
    color: $dark-charcoal;
    &--open {
      background-color: #fff;
    }
    &-contents {
      background-color: #fff;
      color: $dark-charcoal;
    }
  }

  .tile-checkbox {
    .check-container {
      background-color: $white;
      border-color: #bab9ba;
    }

    &--checked .check-container {
      background-color: $medium-teal;
      border-color: $medium-teal;
    }
  }
}
