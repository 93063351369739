@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.lifecycle-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;

  &-row {
    display: flex;
    flex-direction: row;
    height: 36px;
    margin-bottom: 12px;
    align-items: center;
    color: $ge-gray-h3;
    font-size: 12px;
  }

  &-label {
    font-size: 12px;
    color: $ge-gray-h3;
    flex-grow: 1;
    padding-right: 12px;
  }

  &-health {
    display: flex;
    padding-bottom: 5px;
    justify-content: flex-start;
  }

  .date-picker {
    margin-left: 10px;
  }

  &.expandable-section {
    .header-content {
      font-size: 13px;
      font-weight: 700;
    }
  }

  &.expandable-section .content {
    padding-left: 0;
  }
}
