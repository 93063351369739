@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.toggleswitch {
  position: relative;
  width: 42px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .toggleswitch-checkbox {
    display: none;
  }
  .toggleswitch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 18px;
    padding: 0;
    line-height: 18px;
    border: 0px solid #999999;
    border-radius: 25px;
  }
  .toggleswitch-label:before {
    content: '';
    display: block;
    width: 25px;
    margin: -3.5px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border-radius: 25px;
    box-shadow: none;
  }

  .toggleswitch-checkbox:checked + .toggleswitch-label .toggleswitch-inner {
    margin-left: 0;
  }
  .toggleswitch-checkbox:checked + .toggleswitch-label:before {
    right: 0px;
  }

  .toggleswitch-disabled {
    cursor: not-allowed;
  }

  &.dark {
    .toggleswitch-label {
      background-color: $ge-blue;
    }
    .toggleswitch-label:before {
      background: $ge-gray-h3;
    }
    .toggleswitch-checkbox:checked + .toggleswitch-label {
      background-color: $ge-blue;
    }
    .toggleswitch-checkbox:checked + .toggleswitch-label,
    .toggleswitch-checkbox:checked + .toggleswitch-label:before {
      border-color: transparent;
    }
    .toggleswitch-checkbox:checked + .toggleswitch-label:before {
      background-color: #fff;
    }
  }
  &.light {
    .toggleswitch-label {
      background-color: $ge-blue;
    }
    .toggleswitch-label:before {
      background: $ge-gray-h3;
    }
    .toggleswitch-checkbox:checked + .toggleswitch-label {
      background-color: $ge-blue;
    }
    .toggleswitch-checkbox:checked + .toggleswitch-label,
    .toggleswitch-checkbox:checked + .toggleswitch-label:before {
      border-color: transparent;
    }
    .toggleswitch-checkbox:checked + .toggleswitch-label:before {
      background-color: #fff;
    }
  }
}
