@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.hierarchy-tree-view {
  .node-wrapper {
    white-space: pre;
  }
  .tree-node {
    display: flex;
    align-items: center;
    min-height: 35px;
    .tree-icon {
      margin-right: 8px;
      border-radius: 50%;
      background-color: transparent;
      border-color: $ge-border;
      cursor: pointer;
      background-repeat: no-repeat;
      width: 13px;
      height: 13px;
      background-size: cover;
      text-indent: -9999px;
      padding: 6px;
      &.open-icon {
        background-image: url(/resources/images/plus-circle.svg);
      }
      &.close-icon {
        background-image: url(/resources/images/minus-circle.svg);
      }
      &.empty-icon {
        background-color: $white;
        width: 5px;
        height: 5px;
        padding: 2px;
        margin-top: -2px;
      }
    }
    .node-content-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 6px;
      transition: all 0.2s ease;
      .titles {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .node-title {
          font-size: 13px;
          font-family: 'Roboto', sans-serif;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          color: $white;
        }
      }
      .node-check {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 24px;
        height: 16px;
        padding: 4px;
        font-size: 12px;
        border-radius: 16px;
        margin-left: 20px;
        font-weight: 600;
      }
    }
    &.node-disabled {
      .tree-icon {
        &.open-icon,
        &.close-icon {
          background-color: $secondary-caption;
          height: 5px;
          width: 5px;
          padding: 0;
          margin: 0 12px 0 4px;
        }
      }
      .titles .node-title {
        color: #91a6ba;
      }
    }
  }
  .tree-wrapper {
    width: 50%;
    padding: 0 0 0 16px;
    &.simple {
      overflow: auto;
    }
  }
  .lib-icon {
    margin-top: 100px;
  }
}
