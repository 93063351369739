@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.activity-log-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 375px;
  width: 100%;

  .panel-header {
    border-bottom: 1px solid $ge-border;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 0;
    @include applyTheme($themes) {
      border-color: themed('background-ge-dark');
    }

    h1 {
      margin: 0 0 0 25px;
      font-weight: 500;
      color: $ge-gray-h3;
    }
    &--expanded {
      padding: 0 40px;
    }
  }

  .activity-log-events {
    display: flex;
    height: 100%;
    min-height: 0;
    &--expanded {
      padding: 0 40px;
    }
  }

  .activity-log-row {
    border-bottom: 1px solid $ge-border;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px 25px;
    width: 100%;
    @include applyTheme($themes) {
      border-color: themed('border-ge');
    }

    .status-section {
      display: flex;
      align-items: center;
    }

    .status-bar-container {
      width: 100%;
    }

    .activity-contents {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .event-row {
      display: flex;
      justify-content: space-between;
      min-height: 30px;

      &.event-header {
        text-transform: capitalize;
      }
    }

    .timestamp {
      color: $medium-gray;
      text-align: right;
    }

    .progress-label {
      color: $medium-gray;
      font-size: $caption-text;
      margin-top: 5px;
    }
    .navigation-link {
      align-items: center;
      color: $light-teal;
      display: flex;
      font-size: $caption-text;
      justify-content: flex-end;
      text-decoration: underline;
      flex-grow: 2;
      background: transparent;
      border: none;
      cursor: pointer;
      max-height: 25px;
      @include applyTheme($themes) {
        color: themed('ge-link');
      }
      .nav-text {
        padding: 0px;
      }

      &:disabled {
        color: $light-gray;
      }

      &:hover,
      &.light:disabled,
      &.dark:disabled {
        .nav-text {
          background-color: transparent;
        }
      }
    }
    .expandable-section {
      .header {
        .header-content {
          align-items: center;
          color: $light-teal;
          display: flex;
          font-size: $caption-text;
          justify-content: flex-end;
          text-decoration: underline;
          flex-grow: 2;
          @include applyTheme($themes) {
            color: themed('ge-link');
          }

          .more-details-toggle {
            background-color: transparent;
            border: none;
            color: inherit;
            cursor: pointer;
            padding: 0;
            pointer-events: none;
          }
        }

        .toggle-content {
          color: $medium-gray;
          font-size: $caption-text;
          @include applyTheme($themes) {
            color: themed('text-main');
          }

          .toggle {
            @extend .flex-centered;
          }
        }
      }

      .content {
        padding: 0;

        .event-additional-info {
          text-transform: capitalize;
        }
      }
    }
  }

  .subjobs-info {
    background-color: $panel-black;
    border-radius: 4px;
    font-size: $caption-text;
    max-height: 75px;
    margin-bottom: 10px;
    padding: 5px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    @include applyTheme($themes) {
      background-color: themed('background-ge-dark');
    }

    &-row {
      align-items: flex-start;
      display: flex;
      color: $medium-gray;
      justify-content: space-between;
      margin: 2px;
      @include applyTheme($themes) {
        color: themed('text-white');
      }

      span {
        display: flex;
        align-items: flex-start;
        width: 100%;
      }

      .subjob-label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      &-description-column {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-width: 0;
        .warning-description {
          display: flex;
          width: 250px;
        }
      }

      &-description-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .status {
        width: fit-content;
      }
      .subjob-status-description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
      }
    }

    .failed-status {
      color: #ff686d;
    }

    .material-icons {
      font-size: $body-text;
      margin-right: 5px;
    }
  }
}
