@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.panel-tabs {
  max-height: 805px;
  width: 100%;

  .tab-container {
    width: inherit;
  }

  .save-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    align-content: center;

    .save-feedback--error {
      align-items: center;
      color: $warning-red;
      display: flex;
      margin-right: 20px;

      i {
        margin-right: 10px;
      }
    }
  }
}
