@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.search-container {
  @extend .flex-centered;
  flex-shrink: 0;
  height: 55px;
  padding: 15px;

  .search-input-wrapper {
    position: relative;
    width: 100%;
    &::after {
      color: $medium-gray;
      font-family: 'Material Icons';
      font-size: $title-text;
      content: 'search';
      position: absolute;
      right: 0;
      top: 7px;
      width: 30px;
    }
  }

  .search-input {
    border: none;
    border-radius: 35px;
    font-family: 'Roboto', sans-serif;
    font-size: $body-text;
    height: 32px;
    outline: none;
    padding: 5px 36px 7px 15px;
    width: 100%;
    &::-ms-clear {
      display: none;
    }
    &::placeholder {
      color: $medium-gray-90;
    }
  }

  &.dark {
    background-color: $dark-charcoal;
    border-bottom: 1px solid $panel-gray;

    .search-input {
      background-color: $panel-black;
      color: $white;
      &:focus {
        border-color: $light-teal;
        // box-shadow: 0 0 7px $light-teal;
      }
    }
  }

  &.light {
    background-color: $ge-dark;
    border-bottom: 1px solid $light-gray;

    .search-input {
      background-color: rgba(209, 209, 209, 0.3);
      color: $dark-charcoal;
      &:focus {
        border-color: $ge-dark;
        box-shadow: 0 0 7px $light-teal;
      }
    }
  }
}
