@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.card {
  border-radius: 4px;
  padding: 0;
  margin: 10px;

  .border {
    padding: 10px 15px;
  }

  header {
    display: flex;
    align-items: center;

    .card-menu {
      flex-grow: 1;

      .title-menu {
        .title {
          font-size: $body-text;
          margin: 2px 0;
        }
        .secondary-title {
          font-size: $caption-text;
          font-weight: normal;
          margin: 2px 0;
        }
      }
    }
    .button-menu {
      flex-grow: 0;
    }
  }
  .content {
    padding: 10px 15px;
    font-size: $body-text;
  }
}

.card.dark {
  background-color: $ge-dark;
  box-shadow: 0 4px 8px -2px $black, 0 19px 38px -2px $black;
  color: #a0a0a0;
  .content {
    border-top: 1px solid #3b3b3b;
  }
  .content.no-border {
    border-top: none;
  }
}

.card.light {
  background-color: $ge-dark;
  border: 1px solid $ge-border;
  //  box-shadow: 0 1px 3px $lighter-gray-opacity-70, 0 1px 2px $lighter-gray-opacity-70;
  color: $white;
  .content {
    border-top: 1px solid $ge-border;
  }
  .content.no-border {
    border-top: none;
  }
}
