@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.accordion {
  min-height: 28px;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: $article-text;
  background-color: $ge-dark;
  color: $ge-gray-h3;
  border: 1px solid $ge-border;
  .accordion-header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 2px 8px;
    border-radius: 5px;
    justify-content: space-between;
    color: $ge-gray-h3;
    background-color: $ge-dark;
    .title {
      font-size: $article-text;
      margin: 5px 0;
      min-width: 170px;
    }
    .button-menu {
      display: flex;
      position: absolute;
      top: 17%;
      right: 0;

      .icon-btn-container {
        .custom-btn {
          &:hover {
            background-color: transparent;
          }
          .icon-container {
            svg {
              fill: $white;
              color: $white;
            }
          }
        }
      }

      .icon-btn-container .icon-btn,
      .icon-container {
        outline: none !important;
      }
    }
    &.active {
      border-radius: 5px 5px 0 0;
    }
  }
  .content {
    padding: 5px 8px;
    border-radius: 0px 0px 5px 5px;
    background-color: $ge-dark;
    // min-height: 200px;
  }
  &.include-border {
    padding: 2px 5px;
    border: 1px solid $ge-border;
  }
}
