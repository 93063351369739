@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '~flatpickr/dist/flatpickr.min.css';
@import '../styles/base';

.date-picker {
  align-items: center;
  display: flex;
  height: 36px;

  &__btn {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: $icon-size;
    height: 100%;
    padding: 0 10px;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  &__input {
    border-radius: 3px;
    width: 180px;
    height: 30px;
    padding: 0 5px;
    font-size: $body-text;
  }

  // Input and Button styles for dark theme
  &.dark {
    .date-picker__btn {
      color: $white;
      &:hover,
      &:focus {
        color: $white;
      }
      &:disabled {
        color: $dark-gray;
        cursor: not-allowed;
      }
    }

    .date-picker__input {
      background-color: $dark-charcoal;
      border: 1px solid $dark-gray;
      color: $light-gray;
      &:disabled {
        color: $dark-gray;
        cursor: not-allowed;
      }
    }
  }

  // Input and Button styles for light theme
  &.light {
    .date-picker__btn {
      color: $white;
      background-color: $ge-blue;
      &:hover,
      &:focus {
        color: $white;
        background-color: $ge-button-hover;
      }
      &:disabled {
        color: $very-lighter-gray;
        cursor: not-allowed;
      }
    }

    .date-picker__input {
      background-color: $ge-gray-blue;
      border: 1px solid transparent;
      color: $white;
      &:disabled {
        color: $very-lighter-gray;
        cursor: not-allowed;
      }
    }
  }
  &.primary {
    @include applyTheme($themes) {
      .date-picker__input {
        border: 1px solid themed('contrast-border');
        font-size: $article-text;
        width: 100%;
        height: 32px;
      }
    }
  }
}

.DatePicker-calendar-instance {
  border-radius: 0;
  // box-shadow: 0 3px 13px rgba(0, 0, 0, 0.18);

  .flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
    color: $ge-gray-h3;
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    color: $white;
    fill: $white;
    &:hover {
      svg {
        color: $white;
        fill: $white;
      }
    }
  }

  .flatpickr-month,
  .flatpickr-day {
    color: $white;
  }

  .flatpickr-weekday {
    color: $ge-gray-h3;
  }

  .flatpickr-months {
    @extend .flex-centered;
    height: 40px;

    .flatpickr-prev-month,
    .flatpickr-next-month {
      height: 14px;
      padding: 0;
      position: relative;
      width: 40px;
    }
  }

  .flatpickr-current-month {
    @extend .flex-centered;
    font-family: 'Roboto', sans-serif;
    font-size: $body-text;
    padding: 0;

    .cur-month,
    .cur-year {
      display: flex;
      align-items: center;
      font-weight: 400;
      height: 20px;
    }

    .cur-month {
      padding-top: 1px;
      font-size: $body-text;
    }

    .numInputWrapper {
      height: 19px;
      color: $white;
    }
  }

  .flatpickr-weekday {
    font-weight: 400;
  }

  &.arrowTop:before,
  &.arrowTop:after,
  &.arrowBottom:before,
  &.arrowBottom:after {
    display: none;
  }

  .today {
    border-color: transparent;
    &:hover {
      color: $white;
    }
  }
  span.flatpickr-monthSelect-month {
    display: inline-block;
    position: relative;
    width: 33%;
    cursor: pointer;
  }
  &.flatpickr-dark {
    border: 1px solid $dark-gray;

    .today {
      color: $white;
      &:hover,
      &.selected {
        color: $white;
      }
    }

    .flatpickr-day:focus,
    .selected:focus {
      background-color: transparent;
      border: 1px solid $white;
      color: white;
    }

    .flatpickr-current-month input.cur-year[disabled],
    .flatpickr-current-month input.cur-year[disabled]:hover {
      color: $white;
      display: flex;
    }

    .flatpickr-monthDropdown-months {
      background: $medium-charcoal;
    }
  }

  &.flatpickr-light {
    border: 1px solid $ge-border;
    background-color: $ge-gray-blue;
    .today {
      color: $white;
      &:hover,
      &.selected {
        color: $white;
        background-color: transparent;
        border: 1px solid $white;
      }
    }

    .flatpickr-day:focus,
    .selected:focus {
      background-color: transparent;
      border: 1px solid $white;
      color: white;
    }

    .flatpickr-day:hover,
    .selected:hover {
      background-color: transparent;
      border: 1px solid $white;
      color: white;
    }
  }
}
