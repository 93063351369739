@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/_theme.scss';

.file-upload-btn {
  @extend .flex-centered;
  width: 150px;
  height: 45px;
  border-radius: 4px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;

  @include applyTheme($themes) {
    color: #fff;
    background-color: themed('background-ge-blue');
    &:hover {
      background-color: themed('background-ge-blue');
    }
    &.disabled {
      background-color: themed('contrast-dark');
    }
  }

  input[type='file'] {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    height: 40px;
    width: 150px;
  }

  i {
    margin-right: 10px;
  }
}
