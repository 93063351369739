@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.qsts-menu {
  .menu-row {
    flex-grow: 1;
    margin: 10px 0;

    .label {
      margin-bottom: 10px;
    }
  }
  .checkbox-rerun-failed-pf {
    display: flex;
    align-items: center;
    margin: 15px 0;
  }
  .info-text-placeholder {
    @include applyTheme($themes) {
      color: themed('text-disabled');
    }
  }
}
