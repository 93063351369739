@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.service-dashboard {
  width: 100%;

  &-permissions,
  .service-status-tile {
    @include applyTheme($themes) {
      color: themed('text-contrast');
    }
  }
  .spinner {
    line-height: 10px;
  }
}
