@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.icon {
  &-primary {
    .icon-background {
      @include applyTheme($themes) {
        fill: themed('primary-icon-background');
      }
    }
    .icon-foreground {
      @include applyTheme($themes) {
        fill: themed('primary-icon-foreground');
      }
    }
  }
  &-secondary {
    .icon-background {
      @include applyTheme($themes) {
        fill: themed('secondary-icon-background');
      }
    }
    .icon-foreground {
      @include applyTheme($themes) {
        fill: themed('secondary-icon-foreground');
      }
    }
  }
  &-disabled {
    .icon-background {
      @include applyTheme($themes) {
        fill: themed('disabled-icon-background');
      }
    }
    .icon-foreground {
      @include applyTheme($themes) {
        fill: themed('disabled-icon-foreground');
      }
    }
  }
}
