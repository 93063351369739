@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.icon-file-input-container {
  input[type='file'] {
    display: none;
    cursor: pointer;
  }
  border-radius: 5px;

  .icon-container {
    @extend .flex-centered;
    height: 24px;
    width: 24px;
    padding: 0;

    cursor: pointer;

    font-size: $icon-size;

    .material-icons {
      font-size: inherit;
    }
  }

  &.disabled .icon-container {
    cursor: not-allowed;
  }

  &.dark {
    .icon-container {
      color: #707070;
    }

    &:hover {
      background-color: #343434;

      .icon-container {
        color: $ge-blue;
      }
    }

    &.disabled {
      .icon-container {
        color: #444444;
      }

      &:hover {
        background-color: transparent;

        .icon-container {
          color: #444444;
        }
      }
    }
  }

  &.light {
    .icon-container {
      color: $white;
    }

    &:hover {
      // background-color: rgba($ge-blue, 0.7);
      color: $ge-blue;

      .icon-container {
        color: $ge-blue;
      }
    }

    &.disabled {
      .icon-container {
        color: #e2e2e2;
      }

      &:hover {
        background-color: transparent;

        .icon-container {
          color: #e2e2e2;
        }
      }
    }
  }
}
