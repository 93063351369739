@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.violation-summary-container {
  width: 100%;
  .violation-section {
    .title {
      font-size: 12px;
      color: $ge-gray-h3;
      font-weight: 700;
    }
    .violations-box-empty {
      padding: 10px 0;
      font-size: 12px;
      font-weight: 700;
      @include applyTheme($themes) {
        background-color: themed('background-dark');
        color: themed('color-h3-ge') !important;
      }
    }
    .non-expandable-section-content {
      display: flex;
      flex-direction: column;
      .category-section {
        display: flex;
        .header-content {
          font-size: $body-text;
        }
        .header .toggle {
          font-size: $body-text;
        }
        .violations-box {
          font-size: $body-text;
          padding: 10px 0;
          @include applyTheme($themes) {
            background-color: themed('background-dark');
            color: themed('text-main') !important;
          }
          .violations-header {
            padding-bottom: 10px;
          }
          .violations-list {
            padding: 10px 15px 0 0;
            min-height: 35px;
            max-height: 200px;
            display: flex;
            flex-direction: column;
            .violation {
              display: flex;
              justify-content: space-between;
              padding-bottom: 5px;
              min-height: 20px;
              &-value {
                display: flex;
                justify-content: space-between;
                min-width: 175px;
              }
            }
          }
        }
      }
    }
  }
}
