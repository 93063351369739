@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.battery {
  .battery-list {
    display: flex;
    flex-direction: column;
    width: 355px;
  }

  .battery-legend {
    .battery-legend-text {
      font-weight: 100;
    }
  }

  .uncontrolled {
    padding: 2.5px 0;
    width: 355px;
  }
}
