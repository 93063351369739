@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
#scenario-name.text-input__input {
  border: 1px solid transparent;
  background-color: $ge-gray-blue;
  color: $white;
}

.contingency-settings {
  margin-top: 24px;
  font-size: 14px;
}

.has-error {
  height: 145px;
}
.no-error {
  height: 116px;
}
.section-two,
.section-three,
.section-four {
  background-color: $white;
  width: 100%;

  border: 1px solid #e3e9ef;

  .title {
    height: 38px;
    div {
      margin: 16px;
      font-size: 14px;
    }
  }
  label {
    p {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
  .optional-content-wrapper {
    padding: 0 16px;
  }

  .asset-outage {
    .error-message {
      font-size: 12px;
      color: #d82e24;
      margin-top: 4px;
    }
    .label {
      display: flex;
    }
    .icon-holder {
      position: relative;
      left: 8px;
    }
  }

  .recomment-switches-button {
    color: #0336a4;
    padding: 8px 16px;

    margin-top: 11px;
    &:hover {
      background-color: #e7eaf8;
    }
  }
}

.section-three {
  .error-message {
    font-size: 12px;
    color: #d82e24;
    margin-top: 4px;
  }
  .contingency-settings-card {
    margin-top: -6px;
  }
  .optional-content {
    p {
      font-style: italic;
      padding: 8px 0;
    }
  }
  .no-error-switches {
    height: 153px;
  }
}

.section-four {
  .light .calendar-picker-container.primary .calendar-button {
    font-size: 14px;
  }
  .contingency-settings-card {
    height: 50px;
  }
}
