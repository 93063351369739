@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.seasonal-ratings-viewer {
  display: grid;
  grid-template-columns: auto auto;
  grid-auto-rows: 25px;
  align-items: center;
  max-width: 375px;

  font-size: $article-text;

  .header {
    display: flex;
    font-weight: bold;
    align-items: center;
  }

  .row-info {
    grid-column: span 2;
  }

  .row-header {
    padding-left: 50px;
  }
}
