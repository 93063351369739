@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.hosting-capacity-menu {
  // Phase selection styles
  .checkbox-row {
    display: flex;
  }

  .checkbox-group {
    align-items: center;
    display: flex;
    flex-grow: 1;

    .custom-checkbox--disabled + span {
      @include applyTheme($themes) {
        color: themed('text-disabled');
      }
    }

    span {
      margin: 0 5px;
    }
  }

  // Mode Selection Styles
  .radio-btn-group {
    .option-icon-group {
      font-size: $body-text;
    }

    .radio-btn {
      height: 15px;
      margin: 0 10px 0 0;
      width: 15px;
    }
  }
}
