@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.customer-program-selector {
  width: 230px;
  flex-flow: wrap;
  @extend .flex-centered;

  > :first-child {
    margin-right: 10px;
  }
}
