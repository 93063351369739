@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.droop-curve-chart {
  margin: 20px 0;
  @include applyTheme($themes) {
    color: themed('text-main');
  }
  .content {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    h4 {
      margin: 7px 0;
      align-self: center;
      font-weight: 400;
      font-size: $body-text;
      text-transform: capitalize;
    }

    .chart-pane {
      height: 250px;
      margin-right: 15px;
      &--expanded {
        height: 250px;
      }
    }

    .legend {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: $caption-text;
    }

    .recharts-text {
      font-size: $body-text;
    }

    .tooltip {
      padding: 10px;
      border: 1px solid black;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: $body-text;

      @include applyTheme($themes) {
        background-color: tryTheme('background-very-dark', rgba, 0.75);
        border-color: themed('background-very-dark');
        color: themed('text-main');
      }

      th {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}
