@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.edit-container {
  width: 275px;
  height: auto;
  z-index: 999;
  position: absolute;
  top: 0;
  right: 35px;
  border-radius: 4px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  @include applyTheme($themes) {
    background-color: themed('background-neutral');
    color: themed('text-contrast');
  }

  .edit-header {
    text-align: left;
    padding: 12px 13px;
    border-bottom: black 1px solid;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .edit-inputs {
      align-items: center;
      display: flex;
      justify-content: space-around;
    }

    .lower-range {
      align-items: center;
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    .dash {
      margin: 0 5px;
      width: 5px;
    }

    .edit-container {
      display: flex;
      flex-direction: row;
    }

    .number-input-group {
      .number-input__input {
        display: inline-flex;
        border: 1px solid $medium-gray;
        border-radius: 4px;
        width: 100%;
        background-color: transparent;
        @include applyTheme($themes) {
          color: themed('text-contrast');
        }
      }

      .number-input-unit {
        background-color: transparent;
        margin-left: 4px;
        padding: 0;
        text-align: center;
        width: 40px;
      }
      &.invalid-value .number-input__input {
        border-color: rgba(255, 0, 0, 0.5);
      }
    }
  }

  .color-picker {
    padding: 12px 40px 8px;
    display: flex;
    flex-wrap: wrap;

    .color-icon {
      padding: 3px;
      font-size: $icon-size;
      width: 48px;
      background-color: transparent;
      border: none;
    }
  }

  .edit-footer {
    display: flex;
    flex-grow: 1;
    padding: 6px 15px;
    justify-content: flex-end;
  }

  .edit-button:last-child {
    margin-left: 12px;
  }
}
