@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.control-mode {
  padding: 5px 0;

  .help-icon {
    user-select: none;
    margin-left: 10px;

    @include applyTheme($themes) {
      color: themed('text-main');
    }
  }

  .tooltip {
    min-width: 34.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tooltip-contents {
      font-size: $caption-text;
      line-height: 18px;
      min-width: 200px;
      padding: 8px;
      right: 30px;
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
        color: themed('text-main');
      }
    }
  }

  .control-mode-aux-content {
    display: flex;
    flex-direction: column;
    .control-mode-title {
      font-size: 13px;
      line-height: 30px;
      color: $ge-gray-h3;
      font-weight: 700;
    }
    $select-width: 230px;
    .control-mode-select {
      display: flex;
      width: $select-width;
    }

    .control-mode-container {
      display: flex;
      width: $select-width;
      align-items: center;
      justify-content: space-between;
      padding-left: 11px; // Align with dropdown text above it
    }
    .tooltip-padding {
      padding-right: 34px; // Width of .tip-span
    }

    .control-mode-row {
      //based on ".asset-panel-values .single-row"
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-shrink: 0;
      font-family: Roboto;
      font-size: 12px;
      justify-content: space-between;
      line-height: 1.15;
      width: 355px;
      margin: 5px 0px;
      padding: 2px 0px;
      p {
        color: $ge-gray-h3;
      }

      .row-value {
        display: flex;
        align-items: center;
      }
    }
  }
}
