@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
@import '../styles/base';

.file-form-btn {
  @extend .flex-centered;
  background-color: transparent;
  border-radius: 50px;
  height: 50px;
  position: relative;
  text-decoration: none;
  width: 50px;

  &--disabled {
    pointer-events: none;
  }

  .file-form-input {
    height: 1px;
    opacity: 0;
    width: 1px;
    cursor: pointer;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;

    &__label {
      cursor: pointer;
      text-align: center;
      height: 50px;
      width: 50px;
    }
  }
}

/** IE11 File Form Hack **/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .file-form-btn .file-form-input {
    width: 50px;
    height: 50px;
    pointer-events: all;
  }
}
