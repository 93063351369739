@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.breadcrumbs {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-separator {
    font-size: 2em;
    margin: 0 10px;
    font-weight: 100;
  }
}
