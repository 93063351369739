@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.login-container {
  @extend .full-size-flex;
  @extend .flex-centered;
  height: 100%;
  width: 100%;

  @include applyTheme($themes) {
    background: radial-gradient(themed('background-dark') 1px, transparent 13%) 0 0,
      radial-gradient(themed('background-dark') 1px, transparent 13%) 22px 22px;
    background-color: themed('background-very-dark');
    background-size: 22px 22px;
    color: themed('text-main');
  }

  .center-modal {
    @extend .flex-centered;
    height: 250px;
    width: 320px;
    border-radius: 8px;
    padding: 10px 31px;
    flex-direction: column;

    .content {
      height: 100%;
      width: 100%;
      @extend .flex-centered;
      flex-direction: column;
      padding: 0;
    }
  }

  .logo-container {
    height: 80px;
    width: 100%;
    flex-shrink: 0;
    padding: 10px 0;

    @include applyTheme($themes) {
      border-bottom: 1px solid themed('background-light');
    }
  }

  .gridos-logo {
    height: 100%;
    width: 100%;
  }

  .contents-container {
    flex-grow: 1;
    width: 100%;
    @extend .flex-centered;
    flex-direction: column;
  }

  .workspace-text {
    margin-top: 5px;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
  }

  .login-button {
    margin-top: 20px;
    width: 180px;
  }

  .spinner {
    width: 180px;
    height: 76px;
  }
}
