@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.violations-panel {
  min-width: 375px;
  display: flex;
  flex-direction: column;

  &--expanded {
    padding: 10px 60px 20px 60px;
  }
  .panel-header {
    display: flex;
    justify-content: center;
    margin: 0;
    padding-left: 15px;
    @include applyTheme($themes) {
      background-color: themed('background-ge-dark');
    }

    h1 {
      font-weight: 700;
      color: $ge-gray-h3;
      font-size: 13px;
    }

    .violation-min {
      display: flex;
      align-items: center;
      .units {
        padding: 0 20px 0 10px;
        font-size: 12px;
        color: $ge-gray-h3;
      }
    }
  }

  .violations-checkbox-area {
    width: 100%;
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    .violations-checkbox-row {
      padding: 7.5px 10px 7.5px 0;
      display: flex;
      justify-content: space-between;
      .violations-checkbox-container {
        display: flex;
        align-items: center;
      }

      .violations-checkbox-label {
        padding-left: 15px;
        height: 15px;
        white-space: pre;
        font-size: 12px;

        .number-input-group {
          position: relative;

          .input-error {
            position: absolute;
            left: -35px;
          }
        }
      }
    }

    table {
      width: 100%;

      .custom-checkbox {
        display: block;
      }
    }
  }
}
