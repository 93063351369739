@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.import-cim-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  height: 100%;
  width: 100%;
  @include applyTheme($themes) {
    background-color: themed('background-very-dark');
    color: themed('text-main');
    line-height: 1.6;
  }

  h1 {
    font-size: $headline-text;
    margin: 5px 0;
    width: 100%;
  }

  .top-nav-container {
    width: 100%;
    .nav-back__a {
      width: 300px;
    }
  }

  .page-contents {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    width: 100%;
  }

  .workspace-name-container {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }

  .import-cim-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85%;
    position: relative;
    padding: 20px 40px;
    width: 75%;
    border-radius: 4px;

    @include applyTheme($themes) {
      background-color: themed('background-dark');
    }

    &-contents {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
  }

  a {
    color: $light-teal;
  }

  .custom-btn {
    align-self: flex-end;
    flex-shrink: 0;
  }

  .upload-container {
    margin-top: 25px;

    .mode-select {
      width: 200px;
      margin: 15px 0 15px 0;
    }
  }

  .email-upload-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .workspace-name {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    padding: 10px 0;

    @include applyTheme($themes) {
      border-bottom-color: themed('background-neutral');
    }

    &__p {
      color: $error;
      font-size: 0.8em;
      margin: 0;
    }
  }

  .workspace-name-label {
    margin-bottom: 20px;
    margin-right: 10px;
    padding-top: 5px;
  }

  .checkbox-row {
    align-items: center;
    display: flex;
    font-size: $body-text;
    margin: 5px 0;

    .custom-checkbox {
      margin-left: 0;
    }
  }

  .text-input .text-input__label {
    flex-shrink: 0;
    margin-right: 20px;
    width: 150px;
  }

  .file-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    width: 100%;

    .file-row-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin: 15px 0;
      max-height: 100%;

      a {
        text-decoration: underline;
        @include applyTheme($themes) {
          color: themed('contrast-light');
        }
      }
    }
  }

  .file-upload-status {
    width: 100%;
    padding-right: 15px;

    .file-row {
      align-items: center;
      display: flex;
      height: 40px;
      justify-content: space-between;
      padding-right: 10px;
      width: 100%;
    }
  }

  .upload-status-error {
    font-size: $caption-text;
    color: #d53831;
  }

  @media (max-height: 750px) {
    .import-cim-modal {
      height: 90%;
      padding: 10px 20px;
    }

    .drag-drop .drag-container .drag-target-container,
    .drag-drop .drag-container {
      height: 65px;
    }

    .drag-drop .add-file-icon {
      font-size: 30px;
    }
  }
}
