@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
/** NetworkLeftRail Styles **/
.network-left-rail {
  background-color: $ge-dark;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  @include applyTheme($themes) {
    background-color: themed('ge-dark');
  }
  &.--collapsed {
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
  &.--expanded {
    border-right: 1px solid #23343f;
    box-shadow: none;
    position: relative;
    top: -2px;
  }
  .all-feeders {
    width: 100%;
    padding: 20px;
    @include applyTheme($themes) {
      background-color: themed('background-neutral');
    }
  }

  .permission-disabled-tip {
    text-align: center;
    .tooltip {
      width: 100%;
    }
    .tooltip-contents {
      z-index: 401; // Allow tooltip to display over leaflet map
      font-weight: normal;
    }
  }
  .left-rail-header {
    display: flex;
    height: 50px;
    flex-shrink: 0;
    .header-btn {
      background-color: transparent;
      border: none;
      border-bottom: 2px solid $dark-gray;
      cursor: pointer;
      flex-grow: 1;
      font-size: $title-text;
      font-weight: bold;
      padding-bottom: 2px;
      transition: 0.2s ease all;
      @include applyTheme($themes) {
        color: themed('text-dark');

        &--active {
          border-bottom-width: 4px;
          border-bottom-style: solid;
          border-color: themed('contrast-light');
          color: themed('contrast-light');
          padding-bottom: 0;
        }

        &:disabled {
          background-color: themed('background-very-dark');
          color: themed('text-disabled');
          cursor: not-allowed;
        }
      }
    }
  }

  .left-rail-tab-panel {
    &.react-tabs__tab-panel--selected {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 87%;
    }

    &.asset-panel {
      .search-container {
        border-bottom: none;
      }
      .asset-search-header {
        border-bottom: 2px solid #d4d4d4;
        @include applyTheme($themes) {
          border-color: themed('background-dark');
        }

        .search-filter-row {
          display: flex;
          align-items: center;
          padding: 5px 10px 10px 10px;
        }
      }
    }

    .search-container {
      border-bottom: none !important;
      .search-input:focus {
        @include applyTheme($themes) {
          box-shadow: 0 0 7px themed('contrast-light');
        }
      }
    }

    .import-data {
      @extend .flex-centered;
      border-top: 1px solid $ge-border;
      border-bottom: 1px solid $ge-border;
      color: #ffffff;
      flex-direction: column;
      flex-shrink: 0;
      font-weight: bold;
      font-size: $title-text;
      height: 125px;
      text-decoration: none;
      width: 100%;
      @include applyTheme($themes) {
        border-color: themed('ge-border');
      }

      &-plus {
        @extend .flex-centered;
        background-color: #0337a4;
        border-radius: 40px;
        border: none;
        color: #ffffff;
        font-size: 60px;
        height: 70px;
        margin: auto;
        width: 70px;
        @include applyTheme($themes) {
          color: themed('background-white');
          background-color: themed('background-ge-blue');

          &--disabled {
            background-color: themed('text-disabled');
          }
        }
      }

      &-text {
        color: $medium-gray;
        margin-top: 15px;
        @include applyTheme($themes) {
          color: themed('text-main');
          &--disabled {
            color: themed('text-disabled');
          }
        }
      }
      &--disabled {
        cursor: not-allowed;
      }
    }

    .asset-selection {
      height: 100%;
      min-height: 0;
      display: flex;
      width: 250px;
      margin-left: 10px;

      &-scrollbar,
      .instance-dropdown-scrollbar {
        .ps__rail-y {
          width: 8px;
          z-index: 100;
        }
        .ps__rail-y.ps--clicking .ps__thumb-y,
        .ps__rail-y:focus > .ps__thumb-y,
        .ps__rail-y:hover > .ps__thumb-y {
          width: 6px;
        }
      }

      .instance-dropdown-scrollbar {
        .ps__rail-y {
          margin-right: 3px;
          &:hover .ps__thumb-y {
            background-color: rgba($light-teal, 0.75);
          }
        }

        .ps__thumb-y {
          background-color: rgba($light-teal, 0.5);
        }
      }
      .asset-type-instance .active-tile {
        background-color: $ge-gray-blue;
        color: $white;
      }
      .tree-node.node-disabled {
        .asset-type-container .asset-type-tile,
        .asset-type-instance__btn {
          color: $white;
        }
      }
    }

    .asset-type-container {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      &-header {
        border: none;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      &:before {
        border-left: 1px solid #ccc;
        height: calc(100% - 37px);
        content: '';
        position: absolute;
        top: 23px;
        left: 6px;
      }
    }

    .asset-type-list {
      margin: 0;
      overflow-y: hidden;
      padding: 0;
      transition: max-height 0.3s ease;
      max-height: 200px;
    }

    .default-tile-border {
      border: none;
      border-top: 1px solid $panel-black;
      border-bottom: 1px solid $panel-black;
      @include applyTheme($themes) {
        border-color: themed('background-very-dark');
      }
    }

    .default-tile-style {
      background-color: transparent;
      border: none;
      border-left: 8px solid transparent;
      color: inherit;
      outline: none;
    }

    .default-tile-hover {
      background-color: $medium-charcoal;
      border: none;
      border-left: 8px solid $medium-charcoal;
      outline: none;
      @include applyTheme($themes) {
        background-color: themed('background-neutral');
        border-color: themed('background-neutral');
      }
    }

    .asset-type-tile {
      border: none;
      background-color: transparent;
      color: $grey-light;
      cursor: pointer;
      margin: 0;
      padding: 0;
      width: 100%;
      outline: none;
      font-size: $small-header;
      &--open {
        background-color: $medium-charcoal;
      }

      &:disabled {
        cursor: default;
        @include applyTheme($themes) {
          color: themed('text-disabled');
        }
      }
      &-contents {
        @extend .default-tile-style;
        color: inherit;
        display: flex;
        justify-content: space-between;
        text-align: left;
        &__p {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          color: $white;
        }
        .asset-count {
          color: $info;
          position: absolute;
          right: 25px;
        }
      }
    }

    .asset-type-instance {
      background-color: transparent;
      font-size: $small-header;
      list-style: none;
      margin: 0;
      padding: 0 0 0 25px;
      cursor: pointer;
      color: $grey-light;
      &::before {
        border-top: 1px solid #ccc;
        content: '';
        width: 20px;
        position: absolute;
        left: 6px;
      }
      &__btn {
        align-items: center;
        background-color: transparent;
        border: none;
        color: $white;
        cursor: pointer;
        display: flex;
        height: 24px;
        overflow: hidden;
        padding: 0;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 135px;
        padding-left: 5px;

        &:hover {
          font-weight: 600;
        }
      }
    }

    .feeder-selection {
      padding: 0 10px;
      .expandable-section .content {
        padding: 5px 0;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .feeder-list-loading {
        @extend .flex-centered;
        color: $light-gray;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .material-icons {
          margin-bottom: 20px;
        }
      }
      .container-tile-icon {
        width: 20px;
        padding-top: 3px;
        .asset-main {
          @include applyTheme($themes) {
            color: themed('text-contrast');
          }
        }
      }
      .feeder-tile {
        border: none;
        padding: 12px;
        color: $light-gray;
        cursor: pointer;
        display: flex;
        outline: none;
        padding: 0;
        width: 95%;

        @include applyTheme($themes) {
          background-color: themed('background-dark');
          color: themed('text-main');
        }

        &-contents {
          display: flex;
          align-items: center;
          height: 24px;
          min-width: 180px;
          margin-right: 5px;

          .feeder-name {
            align-self: left;
            display: inline-block;
            line-height: 24px;
            margin-left: 5px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 110px;
            font-size: 12px;
          }
        }
        &:hover {
          @include applyTheme($themes) {
            color: #ffffff;
          }
        }

        &.new-feeder-button {
          border-bottom: 1px solid #ededed;
          border-top: 1px solid #ededed;
          padding: 12px 20px;
        }

        &.feeder-tile--active,
        &.feeder-tile--active:hover {
          @include applyTheme($themes) {
            background-color: themed('background-ge-text-box');
            color: themed('text-white');
          }
        }
      }
    }
    .container-section {
      @include applyTheme($themes) {
        &.bordered {
          border-bottom: 1px solid themed('background-very-dark');
        }
      }

      .tip-span {
        display: flex;

        .icon-section > * + * {
          margin-left: 5px;
        }
      }
      .feeder-tile,
      .feeder-tile:hover,
      .container-name-tile,
      .container-name-tile:hover {
        background: transparent !important;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      &--active,
      .container-name-tile,
      .container-name-tile:hover {
        @include applyTheme($themes) {
          color: #ffffff;
        }
      }
    }
    .react-tabs__tab-panel--selected {
      padding: 0;
    }
    .tab-title {
      border-bottom: 1px solid $ge-border;
      padding: 16px 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .file-upload-section {
    margin: 10px;
  }
  .themed-tabs {
    display: flex;
    flex-direction: row;
    .tab-container {
      flex-direction: column;
      height: 75px;
    }
    .react-tabs__tab {
      min-width: 40px;
      min-height: 35px;
      border: none;
      padding: 0;
      &--selected {
        background-color: $ge-blue !important;
        svg > g > g {
          fill: #fff;
        }
      }
    }
    .react-tabs__tab-list {
      height: 100%;
      border-bottom: none !important;
      width: 13%;
      border-right: 1px solid $ge-border;
    }
    .tab-icon {
      padding: 7px 0 0 5px;
    }
  }
  .collapse-icon {
    position: absolute;
    top: 16px;
    right: -8px;
    background-color: $ge-blue;
    border-radius: 100%;
    .icon-btn-container {
      .custom-btn {
        width: 15px;
        height: 15px;
        &:hover {
          background-color: $ge-blue;
          border-radius: 100%;
        }
        .material-icons {
          color: #fff;
          font-size: 15px;
        }
      }
    }
  }
}
