@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
/** ValidationErrors styles **/
.validation-errors {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .errors-container {
    height: 100%;
    width: 100%;
    margin: 0 30px;
    @include applyTheme($themes) {
      background-color: themed('background-ge-dark');
      color: themed('text-white');
      border: 1px solid themed('border-ge');
    }
  }

  .errors-list {
    height: 100%;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }

  .message-instance {
    padding: 5px 0;
    font-size: $body-text;
    display: flex;

    &--message {
      align-items: center;
      display: flex;
    }
  }
}
