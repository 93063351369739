@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.error-details-container {
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  margin-top: 15px;
  min-height: 300px;
  width: 100%;
  .remaining-errors {
    padding: 5px;
    .button-text {
      margin-left: 10px;
    }
  }
}

.error-details-contents {
  display: flex;
  flex: 1;
  height: 100%;
  min-height: 0;
  width: 100%;
}

.navigation-container {
  height: 40px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.button-text-next {
  margin-right: 10px;
}

.button-text-previous {
  margin-left: 10px;
}

.next-button {
  margin-left: auto;
}
