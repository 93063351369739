@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.equipment-left-rail {
  box-shadow: 1px 3px 8px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  min-height: 0;
  width: 300px;
  z-index: 4;
  background-color: $white;

  .select-all-row {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 45px;
    padding: 15px;
    border-bottom-color: #ececeb;

    .select-all {
      display: flex;
      flex-grow: 1;
      align-items: center;

      .select-all-label {
        padding-left: 15px;
        color: #262626;
      }
    }

    .icons {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      padding-right: 6px;

      .icon-button {
        border: none;
        background-color: transparent;
        padding: 8px;
        cursor: pointer;
        &:disabled {
          .svg-icon g {
            fill: #ccc;
          }
        }

        .svg-icon {
          height: 15px;
          svg {
            height: 15px;
            g {
              fill: #262626;
            }
          }
        }
      }
    }
  }

  .category-section {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .select-equipment-section {
    display: flex;
    flex-direction: row;
    padding: 15px;
  }

  .library-type-selection {
    display: flex;
    height: 100%;
    min-height: 0;
    border-top: #ececeb;

    .equipment-type-checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 15px;
    }
  }
}
