@import './AssetPanelValues.scss';

.asset-panel {
  &-container {
    display: flex;
    flex-direction: column;
    min-width: 375px;
    min-height: 0;
    height: 100%;
    width: 100%;
  }

  &-instance-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    padding: 0;

    .themed-tabs {
      height: 100%;
      min-height: 0;

      .react-tabs__tab {
        min-width: initial;
        padding: 0;
      }

      .react-tabs__tab-panel--selected {
        padding: 0;
      }
    }
  }

  #node-coordinates {
    width: 100%;
  }
  .asset-title-bar {
    font-size: 13px;
    font-weight: 600;
    padding: 10px 15px;
    border-bottom: 1px solid $ge-border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $ge-gray-h3;
  }

  // Asset Panel Header Styles
  /** List Section Styles **/
  .asset-panel-header,
  .asset-panel-values {
    .section-divider {
      border-top: none;
      border: 1px solid #23343f;
    }
    .list-value-row-label,
    .left-label {
      color: $ge-gray-h3;
      font-size: 13px;
      margin-right: 10px;
    }
  }
  .custom-btn.icon-btn:disabled .node-display-container-icon > div {
    svg > g > g {
      fill: #aaaaaa;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
  .node-info-row {
    margin-top: 4px;
    width: 100%;
  }
  .list-value-row {
    margin-top: 13px;
    flex-shrink: 0;
    font-size: $caption-text;
    display: flex;
    color: $medium-gray;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @include applyTheme($themes) {
      color: themed('text-white');
    }

    &-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }

    &-value {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: $light-teal;
      padding: 0;
      text-align: left;
      color: $ge-link;
      &__p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $ge-link;
      }
      &__a {
        color: inherit;
      }
    }
  }
  &-header {
    align-items: center;
    border-bottom: 1px solid $ge-border;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-height: 70px;
    justify-content: center;
    padding: 10px 15px;
    position: relative;
    &--expanded {
      padding: 15px 60px 5px 60px;
    }

    .asset-panel-header-contents {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 13px;
    }

    &-description {
      width: 100%;
      overflow: hidden;
    }
    .header-btn-container {
      display: flex;
      align-items: center;
      margin-left: auto;
      .icon-btn-container .icon-btn {
        height: 22px;
        width: 22px;
        .material-icons-outlined {
          font-size: 20px;
        }
      }
      .loading-indicator {
        height: '24px';
        width: '30px';
        display: 'flex';
        justify-content: 'flex-end';
        .material-icons {
          color: #959595;
          font-size: 20px;
        }
      }
    }

    .header-btn {
      @extend .flex-centered;
      background-color: transparent;
      border: none;
      color: $medium-gray;
      cursor: pointer;
      flex-shrink: 0;
      font-size: $headline-text;
      height: 30px;
      width: 35px;
      line-height: normal;
      @include applyTheme($themes) {
        color: themed('text-main');
        .st1,
        #path_1_,
        #Rectangle_820,
        #ic_expand_close path {
          fill: themed('text-main');
        }

        &:hover {
          color: themed('contrast-light');
          .st1,
          #path_1_,
          #Rectangle_820,
          #ic_expand_close path {
            fill: themed('contrast-light');
          }
        }

        &:disabled,
        &:disabled:hover {
          color: themed('text-disabled');
          cursor: not-allowed;
          .st1,
          #path_1_,
          #Rectangle_820,
          #ic_expand_close path {
            fill: themed('text-disabled');
          }
        }
      }

      path {
        opacity: 0.9;
      }

      &.clear-asset {
        padding-top: 5px;
      }
    }

    .loading-placeholder {
      @extend .flex-centered;
      flex-shrink: 0;
      height: 24px;
      width: 24px;
      @include applyTheme($themes) {
        color: themed('text-disabled');
      }
    }

    .header-btn-container .icon-btn-container {
      margin-left: 8px;
    }

    .asset-type-container {
      align-self: center;
      display: flex;
      font-weight: 600;
      color: $ge-gray-h3;
    }

    .feedback-container {
      display: flex;
      margin-top: 5px;
      width: 100%;

      .modify-network-error {
        @extend .flex-centered;
        font-size: $caption-text;

        .edit-values-error__i {
          color: red;
          font-size: $body-text;
          margin-right: 5px;
          &.warning {
            color: #ffa500;
          }
        }
      }
    }
  }

  .label-help {
    display: flex;
    align-items: center;

    .help-icon {
      margin-left: 5px;
    }
  }

  &-name {
    background-color: transparent;
    border: none;
    color: $white;
    border-bottom: 1px solid $ge-gray-blue;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 0 0 10px;
    &-noedit {
      border-bottom: none;
      width: 100%;
      overflow: hidden;
    }
    &:focus-visible {
      outline: none;
    }
    &--edited {
      border-bottom: 1px solid $medium-teal;
    }

    &:disabled {
      border-bottom-color: transparent;
    }
  }

  &-section {
    margin: 10px 0 15px 0;

    h4 {
      font-weight: normal;
      margin: 5px 0;
    }
  }

  // Equipment Information Section Styles
  .equipment-header {
    align-items: center;
    display: flex;
    flex-shrink: 0;

    .equipment-type {
      width: 100%;
      p {
        font-size: 13px;
        color: $ge-gray-h3;
      }

      .Select {
        width: 280px;
        &-input {
          padding: 0;
        }
        &-value {
          margin: 0 30px 0 5px;
        }
      }
    }
  }

  &-icon {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    margin-right: 5px;
    width: 30px;

    .line-icon {
      stroke: $solid-blue;
      stroke-width: 4;
    }

    .circle-icon {
      stroke: none;
      fill: $white;
    }

    .icon-elem {
      width: 30px;
      height: 30px;
      background: #cdd7ed;
      padding: 3px;
      border-radius: 5px;
      > div {
        align-self: center;
        height: 100%;
        display: flex;
        align-items: center;
        > svg {
          width: 25px;
          height: 25px;
        }
      }
    }

    .asset-panel__img {
      height: 25px;
      width: 25px;
    }
    .asset-main {
      fill: $solid-blue !important;
    }
  }

  .asset-additional-information {
    padding: 0;
    .expandable-section {
      padding-top: 5px;
    }
    .content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .asset-additional-information.crowded .content {
    width: 96%;
  }

  .modal {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;

    .type-change-modal {
      .modal-message {
        padding: 10px;
      }
      .modal-message__p {
        margin: 5px 0;
      }
    }
    .modal-footer {
      .continue-btn {
        background-color: $medium-teal;
        color: $white;
        margin: 0px;
        padding: 5px 15px;
        &:hover {
          background-color: $light-teal;
          color: $white;
        }
      }

      .cancel-btn {
        background-color: $dark-gray;
        color: $white;

        margin: 0px;
        padding: 5px 15px;
        &:hover {
          background-color: #a0a0a0;
          color: $white;
        }
      }
    }
  }
}

.delete-asset-tooltip ul {
  padding-inline-start: 24px;
}
