@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.about {
  .modal {
    .modal-body {
      padding: 40px 70px;
      .section-divider {
        border-color: #d9d9d9;
      }
    }
    .modal-message {
      font-size: 14px;
      color: #54595e;
    }
  }
  .close-modal-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .modal-message {
    text-align: center;
  }
  .svg-icon {
    &.opus svg {
      width: 183px;
    }
    &.grid {
      & svg {
        width: 110px;
      }
      div {
        display: flex;
      }
    }
    svg {
      height: auto;
      margin: 10px 0;
    }
  }
  .grid-text {
    letter-spacing: 2px;
    font-size: 12px;
    color: #000;
  }
  .packages {
    margin: 15px 0 25px 0;
  }
  .custom-btn.text-blue-btn {
    font-size: 14px;
    height: 12px;
    border-radius: 0;
    padding: 0 15px;
    &:last-of-type {
      border: none;
    }
  }
  .opus-derms {
    margin: 10px 0;
  }
  .link-url {
    margin: 25px 0 15px 0;
  }
}
