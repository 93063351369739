@import "src/components/styles/_colors.scss";@import "position";@import "src/components/styles/_font-sizes";@import "src/components/styles/_skins.scss";@import "src/components/styles/_theme.scss";
.impedance-panel {
  display: flex;
  flex-direction: row;
  width: 100%;

  .impedance-column {
    display: flex;
    flex-direction: column;
    width: 500px;

    &-right {
      padding-left: 50px;
    }
  }
}
